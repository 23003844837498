/*eslint-disable */

import Notes from "./notes/index.vue";
import Push from 'push.js';
import axios from 'axios';
import {
  bus
} from '@/main';
import createGroup from './create-group';
import {
  mapGetters
} from 'vuex';
import moment from 'moment';
import ngoJoinRequestPopup from '@/components/private/ngo-join-request-popup';
import ngoMeetings from './meetings';
import ngoSettings from './settings';
import notes from './notes';
import toast from '@/services/toast';
import videoSocketRef from "@/services/videoNotificationSocket/";

const $ = window.jQuery = require('jquery');
// import audioPlay from '../../../assets/audio/piano.mp3';
var checkChatContact;
export default {
  name: 'workspace',
  components: {
    'notes':Notes,
    'join-request-popup' : ngoJoinRequestPopup,
    'ngo-meeting' : ngoMeetings,
    'ngo-settings' : ngoSettings,
    'create-group' : createGroup,
    'notes':notes
  },
  props: [],
  data() {
    return {
      message: '',
      selectedUser: '',
      submitted: false,
      userChatContent: {},
      chatSocket: '',
      roomChatId: '',
      checkSocketId: '',
      socketCheck: '',
      listLoading: true,
      normalChatListData: [],
      checkDataTaskModal: false,
      blockedUserId: '',
      blockedUserEmail: '',
      chatUserListes: [],
      uploadFile: null,
      filename: '',
      imgSrc: '',
      fileMessage: '',
      roomChatIdForFile: '',
      uploadedFileUrl: '',
      videoSocketStart: '',
      search_term: '',
      userSearchListTabs: false,
      userSearchListTabDetails: false,
      charSearchOfUsersList: [],
      selectedChatFromUserDetails: '',
      searchingContactText: 'Looking for contact',
      is_superuser: false,
      notConnectedUserDetails: '',
      notConnectedUserPopup: false,
      checkDataTaskModal: false,
      chatRequestDetails: '',
      blockSelectUser: false,
      msgNextPageLoader: '',
      selectedRoomIdForChatStart: '',
      chatAllListResults: [],
      chatRepeatedDateList:[],
      msgApiLoader: false,
      msgValue: '',
      yScrollHeight: '',
      notesTabEnabled: 'notes',
      hasInternetConnection: true,
      isOnline: true,
      selfUserId: '',
      checkRoomSocketStatus: false,
      selection_type: 'press_enter',
      messageFeatureStates: false,
      typingUserInfoState: '',
      TypingEventForSelectedUserId: '',
      deleteMessageDataObj: {
        id: '',
        text: '',
        time: '',
        createdBy: ''
      },
      deleteMsgPopupState:false,
      replyMsgDataObj:{
        data:'',
        status:'',
        chatType:''
      },
      replyMsgAlertState: false,
      replyMsgForSeletedId: '',
      totalChatListOfData:null,
      totalChatListOfArray: [],
      totalChatListOfLoadMore:'',
      clickOnFirstItemOfChat:false,
      selectedRoomOfChatUpperInfo:{
        chat_room:''
      },
      forwardMsgPopup:false,
      forwardMsgInfo:'',
      suggestionUserListState:false,
      suggestionUserListData:{
        handle:''
      },
      messageContentDraftValue:'',
      suggestionUserListStateForFileAttach:false,
      selfUserInfo:null,
      joinRequestPopupState:false,
      joinId:'',
      createGroupPopupState:false,
      ngoInfoData:null,
      getNGoInfoData:null,
      group_details_info_state:false,
      leaveGroupRoomId:'',
      exitGroupName:'',
      deleteGroupInfoPopup:false,
      someInfoOfGroupState:null,
      typingGroupUsersInfoState:'',
      typingGroupUsersInfoState:'',
      leaveGroupRoomPopupState:false,
      sideBaarExapandState:false,
      getGroupRoomListData:null,
      getGroupRoomListDataResults:[],
      isMobile:false,
      mblRightToolsState:false,
    }
  },
  computed: {
    getAllRoomChatListOfGroupFun() {
      return this.$store.getters.getNgoGroupListGetters;
    },
    getAllRoomChatListOfMemberFun() {
      return this.$store.getters.getNgoMemberChatRoomListGetters;
    },
    getNGoGroupInfoByIDFun() {
      return this.$store.getters.getNgoInfoGetters;
    },
  },
  created() {
    window.addEventListener('resize', this.onResize, {
      passive: true
    })
    this.onResize();
    this.$store.dispatch('getNGOInfoNByIdApiFun', {id:this.$route.params.Id});
    this.$store.dispatch('getNgoMemberChatRoomListFunc', {id:this.$route.params.Id})
    this.$store.dispatch('getGroupListOfNGOFunc', {id:this.$route.params.Id})
    if (this.socketCheck) {
      this.socketCheck.close();
    }
  },
  mounted() {
    if(localStorage.getItem('LoginedUserID')){
      this.selfUserId = localStorage.getItem('LoginedUserID')
      this.selfUserInfo = JSON.parse(localStorage.getItem('LoginedUserInfo'))
    }
    function onGranted() {
      Push.Permission.GRANTED;
    }
    function onDenied() {
      Push.Permission.DENIED;
    }
    Push.Permission.request(onGranted, onDenied)
    // Checking Forwarded Message Notification On Live ======= 
    bus.$on('forwardedMessageNotifications', (data) => {
      if(!data) return;
      var message = data
      var messageID;
      var checkSelectedUserIdOfChat = this.selectedRoomIdForChatStart
      let roomMatchedId = message.targetUsersID.find(item => {
        return item === checkSelectedUserIdOfChat
      });
      let message_id = message.message_id_arr.find(item => {
        if(item.id === checkSelectedUserIdOfChat){
          messageID = item.message_id
          return true;
        }else{
          return false;
        }
      });
      if(roomMatchedId){
      var __self = this;
      var messagesList = document.getElementById('reply_msg_list')
      var forwarded_msg_text = document.createElement("span");
      var share_icon = document.createElement("i");
      var forwarded_msg_content = document.createElement("span");
      forwarded_msg_content.appendChild(document.createTextNode('Forwarded'));
      forwarded_msg_text.setAttribute("class", 'forwarded_msg_text');
      share_icon.setAttribute("class", 'fa fa-share');
      forwarded_msg_text.appendChild(share_icon);
      share_icon.after(forwarded_msg_content);
      var li = document.createElement("div");
      var div = document.createElement("div");
      var p = document.createElement("div");
      var a = document.createElement("a");
      var fileName = document.createElement("a");
      var img = document.createElement("img");
      var span = document.createElement("span");
      var span2 = document.createElement("span");
      var time_wrp = document.createElement("span");
      var time = document.createElement("time");
      let dateTimeAdd = [moment(message.chat_data.current_time).format('h:mm a')];
      time.appendChild(document.createTextNode(dateTimeAdd));
      time.setAttribute("class", "msg_time");
      time_wrp.setAttribute("class", 'msg_time_wrp');
      li.setAttribute("class", 'live_msg');
      li.appendChild(div);
      p.setAttribute("class", 'chat_in_msg_box_wrp');
      var dropdownDiv = document.createElement("div");
      var msg_toggle_option = document.createElement("span");
      var fa_chevron_down = document.createElement("i");
      var dropdown_menu = document.createElement("ul");
      var reply_li = document.createElement("li");
      var delete_li = document.createElement("li");
      var forwardedMessage_li = document.createElement("li");
      dropdownDiv.setAttribute("class", 'dropdown');
      msg_toggle_option.setAttribute("class", 'msg_toggle_option');
      msg_toggle_option.setAttribute("data-toggle", 'dropdown');
      fa_chevron_down.setAttribute("class", 'fa fa-chevron-down');
      dropdown_menu.setAttribute("class", 'dropdown-menu');
      delete_li.onclick = function () {
        _self.deteteMessageFromChatMethod(messageID, message.chat_data.message, message.chat_data.current_time, message.chat_data.selfUserID)
      }
      reply_li.onclick = function() {
        _self.replyMessageOnChatMethod('userChat', message, 'live')
      }
      forwardedMessage_li.onclick = function() {
        _self.forwardMsgToUserMethod(message)
      }
      dropdownDiv.appendChild(msg_toggle_option);
      msg_toggle_option.appendChild(fa_chevron_down);
      msg_toggle_option.after(dropdown_menu);;
      dropdown_menu.appendChild(reply_li);
      dropdown_menu.appendChild(forwardedMessage_li);
      dropdown_menu.appendChild(delete_li);
      reply_li.appendChild(document.createTextNode('Reply'));
      delete_li.appendChild(document.createTextNode('Delete Message'));
      forwardedMessage_li.appendChild(document.createTextNode('Forward Message'));
      if (message.reply_to_msg) {
        var show_msg_of_reply_wrp = document.createElement("span");
        var show_msg_of_reply_name = document.createElement("span");
        show_msg_of_reply_name.style.color = message.chat_data.colour
        show_msg_of_reply_wrp.style.borderLeftColor = message.chat_data.colour
        var show_msg_of_reply_txt = document.createElement("span");
        show_msg_of_reply_wrp.setAttribute("class", 'show_msg_of_reply_wrp');
        show_msg_of_reply_name.setAttribute("class", 'show_msg_of_reply_name');
        show_msg_of_reply_txt.setAttribute("class", 'show_msg_of_reply_txt');
        show_msg_of_reply_name.appendChild(document.createTextNode(message.reply_to_msg.data.sender_user_detail.first_name + ' ' + message.reply_to_msg.data.sender_user_detail.last_name));
        if(message.reply_to_msg.data.file_is){
          show_msg_of_reply_txt.appendChild(document.createTextNode(message.reply_to_msg.data.filename))
        }else{
          show_msg_of_reply_txt.appendChild(document.createTextNode(message.reply_to_msg.data.message))
        }
        show_msg_of_reply_wrp.appendChild(show_msg_of_reply_name);
        show_msg_of_reply_name.after(show_msg_of_reply_txt);
        p.appendChild(show_msg_of_reply_wrp);
      }
      p.appendChild(dropdownDiv);
      dropdownDiv.after(forwarded_msg_text)
      li.setAttribute("id", 'chatMessageId' + messageID);
      var forwardMsgFileI = message.chat_data.message_detail.file
      if (forwardMsgFileI) {
        div.setAttribute("id", 'live_msg_in_file_recieved');
        var forwardMsgFileI = message.chat_data.message_detail.file
        var msgExt = forwardMsgFileI.substr((forwardMsgFileI.lastIndexOf('.') + 1));
        msgExt = msgExt.substring(0, msgExt.lastIndexOf("?"))
        li.appendChild(div);
        div.appendChild(p);
        p.appendChild(span);
        span.setAttribute("class", 'msg_in_file');
        if (msgExt == 'PNG' || msgExt == 'png' || msgExt == 'jpeg' || msgExt == 'jpg' || msgExt == 'svg' || msgExt == 'gif' || msgExt == 'webp') {
          img.setAttribute("src", forwardMsgFileI);
          var msg_img_render_in_chat = document.createElement("span");
          msg_img_render_in_chat.setAttribute("class", 'msg_img_render_in_chat');
          span.appendChild(msg_img_render_in_chat);
          msg_img_render_in_chat.appendChild(a);
          a.setAttribute("href", forwardMsgFileI);
          a.setAttribute("target", "_new");
          a.appendChild(img);
          img.setAttribute("class", 'img-fluid');
        } else {
          img.setAttribute("src", _self.checkFileExtensionForChatMessage(forwardMsgFileI));
          var msg_file_render_in_chat = document.createElement("span");
          msg_file_render_in_chat.setAttribute("class", 'msg_file_render_in_chat');
          span.appendChild(msg_file_render_in_chat);
          msg_file_render_in_chat.appendChild(a);
          a.setAttribute("href", forwardMsgFileI);
          a.setAttribute("target", "_new");
          a.appendChild(img);
          img.setAttribute("class", 'img-fluid');
          var msg_file_name = document.createElement("span");
          msg_file_name.setAttribute("class", 'msg_file_name');
          msg_file_name.appendChild(document.createTextNode(forwardMsgFileI.split('/').slice(-1)[0].split('?')[0]));
          img.after(msg_file_name)
        }
        if (message.chat_data.message_detail.message) {
          fileName.appendChild(document.createTextNode(message.chat_data.message_detail.message));
          fileName.setAttribute("href", forwardMsgFileI);
          fileName.setAttribute("class", 'msg_link');
          span.after(fileName);
        }
        span2.appendChild(time);
        span2.setAttribute("class", 'msg_time_wrp');
        p.appendChild(span2);
      }else{
        if (message.chat_data.message.split(':/')[0] == 'http' || message.chat_data.message.split(':/')[0] == 'https') {
          a.setAttribute("href", message.chat_data.message);
          a.setAttribute("target", "_new");
          a.setAttribute("class", 'hyper_link');
          a.appendChild(document.createTextNode(message.chat_data.message));
          p.appendChild(a);
          div.appendChild(p);
          time_wrp.appendChild(time);
          p.appendChild(time_wrp);
        } else {
          a.setAttribute("href", "javascript:void(0)");
          a.setAttribute("class", 'msg_link_chat');
          a.appendChild(document.createTextNode(message.chat_data.message));
          p.appendChild(a);
          div.appendChild(p);
          time_wrp.appendChild(time);
          p.appendChild(time_wrp);
        }
      }
      div.setAttribute("class", 'sent');
      messagesList.appendChild(li);
      // _self.socketMsgReadFun(messageID)
      var elmnt = document.getElementById("chat_msg_list_scroll");
      var y = elmnt.scrollHeight;
      $('#chat_msg_list_scroll').scrollTop(y)
      }
    })
    bus.$on('MessageDeleteForChatOfAllNotification', (data) => {
      let dateTimeAdd = [moment(data.targetForChatMsgTime).format('h:mm a')];
      $('#chatMessageId' + data.targetForChatMsgId + ' ' + '.dropdown').remove()
      $('#chatMessageId' + data.targetForChatMsgId + ' ' + '.show_msg_of_reply_wrp').remove()
      $('#chatMessageId' + data.targetForChatMsgId + ' ' + 'label').remove()
      $('#chatMessageId' + data.targetForChatMsgId + ' ' + '.chat_in_msg_box_wrp').addClass('delete_msg_show_box')
      $('#chatMessageId' + data.targetForChatMsgId + ' ' + 'a').html("<i aria-hidden='true' class='fa fa-ban'></i> This message was deleted")
      $('#chatMessageId' + data.targetForChatMsgId + ' ' + '.msg_in_file').html("<i aria-hidden='true' class='fa fa-ban'></i> This message was deleted")
    })
    bus.$on('getUserInfoTypingEventForSelectedGroupBus', (data) => {
      this.typingGroupUsersNameState = data
    })
    bus.$on('TypingEventForSelectedGroupBus', (data) => {
      this.typingGroupUsersInfoState = data
      if (data.message == 'stop') {
        this.typingGroupUsersInfoState = '';
        this.typingGroupUsersNameState = ''
      }
    })
    bus.$on('TypingEventForSelectedUserBus', (data) => {
      this.typingUserInfoState = data
      if (data.message == 'stop') {
        this.typingUserInfoState = '';
      }
    })
    bus.$on('msgIndicationStateBus', (data) => {
      if (!data) return;
      if (this.selectedChatFromUserDetails.id == data.selfUserID) {
        if (data.ingroupusers) return;
        $('.msg_sent_ind').addClass('froce_read')
        $('.msg_unsent_ind').addClass('froce_read')
      }
    })
    bus.$on('updateCurrentUserChatUpperInfoDataBus', (data) => {
      this.selectedRoomOfChatUpperInfo.chat_room = data.chat_room
    })
    bus.$emit('clearMessageNotifications', {});
    bus.$on('userUpdateStatus', (data) => {
      if(this.totalChatListOfArray && this.totalChatListOfArray.length > 0){
        let user = this.totalChatListOfArray.find(item => {
          if(item.user){
            return item.user.id == data.userID
          }
        });
        if (user) {
          user.user.is_online = data.is_online;
        }
      }
    });
    bus.$on('userSelfStatus', (data) => {
      if(this.totalChatListOfArray && this.totalChatListOfArray.length > 0){
        let user = this.totalChatListOfArray.find(item => {
          if(item.user){
            return item.user.id == data.userID
          }
        });
        if (user) {
          user.user.current_status = data.current_status;
          user.user.status_time = data.status_time;
        }
      }
    });
    // display user chat list for code here
    checkChatContact = setInterval(() => {
      if (this.totalChatListOfData && this.getGroupRoomListData) {
        if (this.totalChatListOfArray.length > 0 || this.getGroupRoomListDataResults.length > 0) {
         if(!this.isMobile){ 
          $('.chat_user_listed').first().addClass('active');
          document.querySelector('.chat_user_listed.active .chats_tab_name').click()
          clearInterval(checkChatContact)
        }
        }
      }
    }, 200)
    // Searchbar enabled of user for chat code here
    $(document).ready(function () {
      $('.chat_menuburger').click(function () {
        $('.chat_menuburger_wrp').toggleClass('active')
      })
      $(document).click(function (event) {
        //if you click on anything except the modal itself or the "open modal" link, close the modal
        if (!$(event.target).closest(".chat_menuburger, .chat_menu_list").length) {
          $(".chat_menuburger_wrp").removeClass("active");
        }
      });
      // User Search For New Chat
      $('.user_search_for_new_chat .form-control').on({
        focus: function () {
          $('.user_search_for_new_chat').addClass('search_enabled')
        },
        blur: function () {
          $('.user_search_for_new_chat').removeClass('search_enabled')
        }
      });
    })
    // Msg Loading Method Here
    var _self = this
    $('#chat_msg_list_scroll').on("scroll", function () {
      var yScrollHeight;
      var elmntList = document.getElementById("chat_msg_list_scroll");
      yScrollHeight = elmntList.scrollHeight
      if (0 == $('#chat_msg_list_scroll').scrollTop()) {
        if (_self.msgNextPageLoader) {
          _self.msgApiLoader = true
          axios.get(_self.msgNextPageLoader).then((response) => {
            _self.msgNextPageLoader = ''
            if (response.status == '200') {
              _self.msgNextPageLoader = response.data.data.data.next
              _self.msgApiLoader = false
              _self.chatAllListResults = [].concat(_self.chatAllListResults, response.data.data.data.results);
              _self.chatRepeatedDateList = [].concat(response.data.data.data.repeated_dates_arr);
              setTimeout(() => {
                var recentScrlHeight = elmntList.scrollHeight
                elmntList.scrollTop = recentScrlHeight - yScrollHeight
              }, 10)
            }
          }).catch((err) => {
          })
        }
      }
    });
    $('#contacts_list').on("scroll", function () {
      var yScrollHeight;
      var elmntList = document.getElementById("contacts_list");
      yScrollHeight = elmntList.scrollHeight
      var scrollTopEvt = $('#contacts_list').scrollTop() + elmntList.clientHeight + 1
      if (scrollTopEvt  > yScrollHeight) {
        if (_self.totalChatListOfLoadMore) {
          axios.get(_self.totalChatListOfLoadMore).then((response) => {
            _self.totalChatListOfLoadMore = ''
            if (response.status == '200') {
              _self.totalChatListOfArray = [].concat(_self.totalChatListOfArray, response.data.data.results);
              _self.totalChatListOfLoadMore = response.data.data.next
            }
          }).catch((err) => {
          })
        }
      }
    });
    bus.$on('deleteMsgPopupStateBus', (data) => {
      this.deleteMsgPopupState = data
    })
    bus.$on('forwardMsgPopupBus', (data) => {
      this.forwardMsgPopup = data
    })
    bus.$on('suggestionUserListStateBus', (data) => {
      this.suggestionUserListState = data
      let valueSpilt = this.messageContentDraftValue.split('@')
      if(valueSpilt.length == 2){
        this.messageContentDraftValue = valueSpilt[0] + '@' + this.suggestionUserListData.handle
      }else if(valueSpilt.length == 3){
        this.messageContentDraftValue = valueSpilt[0] + '@' + valueSpilt[1]  + '@' + this.suggestionUserListData.handle
      }else if(valueSpilt.length == 4){
        this.messageContentDraftValue = valueSpilt[0] + '@' + valueSpilt[1]  + '@'  + valueSpilt[2] + '@' + this.suggestionUserListData.handle
      }
      $('#chat-message-reply-input').focus()
    })
    bus.$on('suggestionUserListStateForFileAttachBus', (data) => {
      this.suggestionUserListStateForFileAttach = data
      this.userNameMentionDataForAttachment()
    })
    bus.$on('joinRequestRecordModalBus', (data) => {
      this.joinRequestPopupState = data
    });
    bus.$on('createGroupPopupBus', (data) => {
      this.createGroupPopupState = data
    });
    bus.$on('updateMessageNotification', (data) => {
      let currentSelected = this.selectedChatFromUserDetails;
      currentSelected = currentSelected.id;
      if (data.fromUserID == currentSelected) return;
      let user = this.totalChatListOfArray.find(item => {
        if(item.user){
         return item.user.id ==  data.fromUserID
        }
      });
      if (user) {
        this.moveToTopIndexSelectedUserOfLiveChatMethod(data.chatRoomId)
        if (data.unreadCount > 0) {
          if (Push.Permission.has()) {
            Push.create(data.msgSenderFullName, {
              body: data.senderMsgData,
              timeout: 4000,
              onClick: function () {
                window.focus();
                this.close();
              }
            })
            user.unread_count = data.unreadCount;
          }else{
            user.unread_count = data.unreadCount;
            this.playSound(audioPlay)
          }
        }
      }
    });
    bus.$on('updateMessageNotificationOfGroupRoom', (data) => {
      let currentSelected = this.selectedChatFromUserDetails;
      currentSelected = currentSelected.id;
      if (data.chatRoomId == currentSelected) return;
      let groupMsgReadStatus = this.getGroupRoomListDataResults.find(item => item.id == data.chatRoomId);
      if (groupMsgReadStatus){
        this.moveToTopIndexSelectedGroupOfChatListMethod(data.chatRoomId)
        if (data.unreadCount > 0) {
          if(Push.Permission.has()) {
            Push.create(data.msgSenderFullName, {
              body: data.senderMsgData,
              timeout: 4000,
              onClick: function () {
                window.focus();
                this.close();
              }
            })
            groupMsgReadStatus.unread_count = data.unreadCount;
          }else{
            groupMsgReadStatus.unread_count = data.unreadCount;
            this.playSound(audioPlay)
          }
        }
      }
    });
  },
  methods: {
    toolActvInMblMethod(){
      this.mblRightToolsState = true
    },
    toolDactvInMblMethod(){
      this.mblRightToolsState = false
    },
    onResize() {
      this.isMobile = window.innerWidth < 769
    },
    /*
    * This Will Hide Show Menu List For Mobile View
    */
    showChatInfoInMBlMethod(){
      $('#sidepanel').addClass('mbl-hide')
      $('#chatContentWrp').addClass('mbl-show')
    },
    hideChatInfoInMBlMethod(){
      if(this.socketCheck){
        this.socketCheck.close();
      }
      this.checkRoomSocketStatus = true
      $('#sidepanel').removeClass('mbl-hide')
      $('#chatContentWrp').removeClass('mbl-show')
      if(this.userSearchListTabs){
        this.searchBarForChatClosedFun();
      }
    },
    /*
    * Get Group Room List Again
    */
    getGroupRoomListAgain() {
      this.$store.dispatch('getGroupListOfNGOFunc', {id:this.$route.params.Id})
    },
    leaveGroupRoomPopupOpned(id, groupName) {
      this.leaveGroupRoomId = id
      this.exitGroupName = groupName
      this.leaveGroupRoomPopupState = true
    },
    leaveGroupRoomPopupClosed() {
      this.leaveGroupRoomPopupState = false
      this.leaveGroupRoomId = ''
      this.exitGroupName = ''
    },
    leaveGroupRoomMethod() {
      this.$store.dispatch('leaveRoomGroupByIdFun', { room_id: this.leaveGroupRoomId }).then((res) => {
        if (res.data.code == '2000') {
          this.getGroupRoomListAgain()
          this.groupSomeInfoDisabled()
          this.leaveGroupRoomPopupClosed()
          $('.chat_user_listed').removeClass('active');
          $('.chat_user_listed').first().addClass('active');
          setTimeout(() => {
            document.querySelector('.chat_user_listed.active .chats_tab_name').click()
          }, 500)
        }
      }).catch((err) => {

      })
    },
    checkTheColorFun(allUser, SelectedUser) {
      for (var i = 0; i < allUser.length; i++) {
        if (allUser[i].user.id == SelectedUser.user.id) {
          return allUser[i].colour;
        }
      }
    },
    groupSomeInfoDisabled() {
      this.group_details_info_state = false,
      this.someInfoOfGroupState = null
    },
    deleteGroupRoomMethod() {
      this.$store.dispatch('removeNgoInfoByIdFunc', { id: this.leaveGroupRoomId }).then((res) => {
        if (res.data.code == '2000') {
          this.getGroupRoomListAgain()
          this.groupSomeInfoDisabled()
          this.deleteGroupRoomPopupClosed()
          $('.chat_user_listed').removeClass('active');
          $('.chat_user_listed').first().addClass('active');
          setTimeout(() => {
            document.querySelector('.chat_user_listed.active .chats_tab_name').click()
          }, 500)
        }
      }).catch((err) => {

      })
    },
    deleteGroupRoomPopupClosed() {
      this.deleteGroupInfoPopup = false
      this.leaveGroupRoomId = ''
      this.exitGroupName = ''
    },
    deleteGroupRoomPopupOpned(id, groupName) {
      this.leaveGroupRoomId = id
      this.exitGroupName = groupName
      this.deleteGroupInfoPopup = true
    },
    checkTheGroupAdminAccessFun(groupData) {
      for (var i = 0; i < groupData.length; i++) {
        if (groupData[i].user.id == this.selfUserInfo.id) {
          return groupData[i].is_admin;
        }
      }
    },
    groupSomeInfoEnabled(data) {
      this.someInfoOfGroupState = data,
        this.group_details_info_state = true
      $(document).click(function (event) {
        if (!$(event.target).closest(".some_details_of_group_wrp, .group_details_btn, .remove_data_task_modal, .create_group_team_popup_close .fa, .group_info_show_btn").length) {
          if ($('.group_cross_icon').hasClass('enabled')) {
            document.querySelector('.group_cross_icon .fa').click()
          }
        }
      });
    },
    createGroupPopupOpenMethod(info){
      this.ngoInfoData = info
      document.body.classList.add('popup_open_body')
      this.createGroupPopupState = true
    },
    joinRequestPopupOpenMethod(id){
      this.joinId = id
      document.body.classList.add('popup_open_body')
      this.joinRequestPopupState = true
    },
    getURLObj() {
      return window.URL || window.webkitURL;
    },
    captureImgOnPasteMethod(e){
      if (e.clipboardData && e.clipboardData.items) {
        var items = e.clipboardData.items;
        for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            var blob = items[i].getAsFile()
            var URLObj = this.getURLObj()
            var source = URLObj.createObjectURL(blob);
            this.uploadFile = blob
            this.imgSrc = source
            this.filename = 'Photo'
            e.preventDefault();
          }
      }
    }
    },
    userNameMentionDataForAttachment(){
      let valueSpilt = this.fileMessage.split('@')
      if(valueSpilt.length == 2){
        this.fileMessage = valueSpilt[0] + '@' + this.suggestionUserListData.handle
      }else if(valueSpilt.length == 3){
        this.fileMessage = valueSpilt[0] + '@' + valueSpilt[1]  + '@' + this.suggestionUserListData.handle
      }else if(valueSpilt.length == 4){
        this.fileMessage = valueSpilt[0] + '@' + valueSpilt[1]  + '@'  + valueSpilt[2] + '@' + this.suggestionUserListData.handle
      }
      setTimeout(() => {
        document.getElementById('textarea_for_file_input').focus()
      }, 100);
    },
    suggestionListPopupClosed(){
      this.suggestionUserListStateForFileAttach = false
      this.suggestionUserListState = false
      this.suggestionUserListData.handle = ''
    },
    getStatusTimeOfMinMethod(sc){
      var cth = moment().format('HH')
      var ctm = moment().format('mm:ss')
      var sth = moment(sc).format('HH')
      var stm = moment(sc).format('mm:ss')
      var cft  = cth*parseInt(60) + parseInt(ctm)
      var sft  = sth*parseInt(60) + parseInt(stm)
      var nt = cft - sft
      return nt;
    },
    urlify(text) {
      var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      return text.replace(urlRegex, function (url, b, c) {
        var url2 = (c == 'www.') ? 'http://' + url : url;
        return '<a href="' + url2 + '" target="_blank" class="hyper_link">' + url + '</a>';
      })
    },
    forwardMsgToUserMethod(data){
      $('body').addClass('body_right_scroll_remove')
      this.forwardMsgPopup = true
      this.forwardMsgInfo = data
    },
    repeatedDateCheckFun(currentDate, NextItem){
      if(NextItem){
        if(currentDate === NextItem.date){
          return true
        }else{
          return false
        }
      }
    },
    /*
    * This Will Move On Top Selected User From List
    */
    moveToTopIndexSelectedUserOfLiveChatMethod(itemId){
      const itemIndex = this.totalChatListOfArray.findIndex(item => item.chat_room === itemId);
      if(itemIndex){
        this.totalChatListOfArray.unshift(this.totalChatListOfArray.splice(itemIndex, 1)[0])
      }
    },
    moveToTopIndexSelectedUserOfChatListMethod(itemId){
      const itemIndex = this.totalChatListOfArray.findIndex(item => item.chat_room === itemId);
      if(itemIndex){
        this.totalChatListOfArray.unshift(this.totalChatListOfArray.splice(itemIndex, 1)[0])
      }
    },
    moveToTopIndexSelectedGroupOfChatListMethod(itemId){
      const itemIndex = this.getGroupRoomListDataResults.findIndex(item => item.chat_room === itemId);
      if(itemIndex){
        this.getGroupRoomListDataResults.unshift(this.getGroupRoomListDataResults.splice(itemIndex, 1)[0])
      }
    },
    /*
    * This Will Reply Selected Message From Chat
    */
    replyMessageOnChatMethod(chatType, msgData,status){
      // console.log(chatType, msgData, status)
      this.replyMsgDataObj.chatType = chatType
      this.replyMsgDataObj.data = msgData
      this.replyMsgDataObj.status = status
      this.replyMsgAlertState = true
      if (status == 'inactive') {
        this.replyMsgForSeletedId = msgData.id
      } else {
        this.replyMsgForSeletedId = msgData.chat_data.message_id
      }
      document.getElementById("chat-message-reply-input").focus();
    },
    cancelReplyMessageOnChatMethod(){
      this.replyMsgDataObj.chatType = ''
      this.replyMsgDataObj.data = ''
      this.replyMsgDataObj.status = ''
      this.replyMsgForSeletedId = ''
      this.replyMsgAlertState = false;
    },
    /*
    * This Will Delete Selected Message From Chat
    */
    deteteMessageFromChatMethod(messageId, MsgText, messageTime, createdBy) {
      this.deleteMessageDataObj.id = messageId
      this.deleteMessageDataObj.time = messageTime
      this.deleteMessageDataObj.text = MsgText
      this.deleteMessageDataObj.createdBy = createdBy
      this.deleteMsgPopupState = true
      $('body').addClass('body_right_scroll_remove')
    },
    /*
    * This Will Scroll Bottom Of ChatBox
    */
    chatScrollBottomMethod() {
      var elmnt = document.getElementById("chat_msg_list_scroll");
      var y = elmnt.scrollHeight;
      var scrollH = elmnt.clientHeight;
      var scrollTop = elmnt.scrollTop
      var FullSH = scrollH + scrollTop
      var scrollD = y - FullSH
      if (parseInt(60) > scrollD) {
        $('#chat_msg_list_scroll').animate({ scrollTop: y }, 20)
      }
    },
    /*
    * This Will Checking Chat Message Position 
    */
    CheckingMessageSentReceivedPositionForSingleRoom(ChatData) {
      if(!ChatData) return;
      if(ChatData.forwarded){
        if (ChatData.data.user_id == this.selfUserId) {
          return 'pchatmsg_sent';
        } else {
          return 'pchatmsg_recieved';
        }
      }else{
        if (ChatData.data.user_id) {
          if (ChatData.data.user_id == this.selfUserId) {
            return 'pchatmsg_recieved';
          } else {
            return 'pchatmsg_sent';
          }
        }else{
          if (ChatData.chat_room_user.user.id == this.selfUserId) {
            return 'pchatmsg_recieved';
          } else {
            return 'pchatmsg_sent';
          }
        }
      }
    },
    DisabledSelfnameFromChatListMethod(ChatData) {
      if (ChatData.data.user_id) {
        if (ChatData.data.user_id == this.selfUserId) {
          return false;
        } else {
          return true;
        }
      } else {
        if (ChatData.chat_room_user.user.id == this.selfUserId) {
          return false;
        } else {
          return true;
        }
      }
    },
    /*
    * this will check the new message notification
    */
    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },
    /*
    * Get Group Room List Again
    */
    todoTabChangedMethod(tabName) {
      this.notesTabEnabled = tabName
    },
    collapseWorkspaceFun(){
      if(this.sideBaarExapandState == true){
        this.sideBaarExapandState = false
      }else{
        this.sideBaarExapandState = true
      }
      $('#frame').toggleClass('collapsed_chat_list')
      $('.slide_left_arrow').toggleClass('active')
      if($('.wrap .meta .name').hasClass('active')){
        setTimeout(() => {
          $('.wrap .meta .name').removeClass('active');
        },500)
      }else{
        $('.wrap .meta .name').addClass('active');
      }
    },
    enabledYearDateFun(msgDateTime) {
      var currentLocalDate = moment().format('YYYY');
      var startDateCheck = moment(msgDateTime).format('YYYY')
      if (currentLocalDate > startDateCheck) {
        return true;
      } else {
        return false;
      }
    },
    newline() { },
    send() { },
    /*
    * This Will Video Call Request Function
    */
    videoCallRequestFun(callerId, callerFirstName, callerLastName) {
      $('.video_call_request').removeClass('call_actv')
      $('#videocallbtn' + callerId).addClass('call_actv')
      let callerFullName = callerFirstName + ' ' + callerLastName
      videoSocketRef.emit({
        message: `${callerFullName} is calling you.`,
        type: "videoCalling",
        displayName: callerFullName,
        callerID: this.selfUserId,
        targetId: callerId,
        second_user_id:callerId,
        'chat_data': {
          'message': 'Missed Call'
        }
      });
      videoSocketRef.$on('videoCallAcceptedNotification', this.getAnswerOfVideoCall);
      videoSocketRef.$on('videoCallDeclinedNotification', this.getDeclinedOfVideoCall);
      videoSocketRef.$on('videoCallAlertPopupClosed', this.notrecievedCall);
      videoSocketRef.$on('autoMissedCallEvent', this.autoMissedCall);
    },
    getAnswerOfVideoCall(getAnswerData) {
      if (this.selfUserId == getAnswerData.returnCallerUserId) {
        this.$router.push('/video/call/one-to-one-peer/#' + getAnswerData.videoCallRoomId)
      }
    },
    getDeclinedOfVideoCall(declinedData) {
      $('#videocallbtn' + declinedData.videoCallDeclinedId).removeClass('call_actv')
    },
    notrecievedCall(notrecievedData) {
      $('#videocallbtn' + notrecievedData.notRecivedCallerId).removeClass('call_actv')
    },
    autoMissedCall(mCallData) {
      $('#videocallbtn' + mCallData.targetId).removeClass('call_actv')
    },
    /*
    * This Will Opned Chat Room With Selected user Of Contact List Method Here
    */
    opnedChatRoomWithSelectedUserFun(userInfo) {
      if(this.isMobile){this.showChatInfoInMBlMethod()}
      this.messageContentDraftValue = ''
      this.cancelReplyMessageOnChatMethod()
      if (this.checkRoomSocketStatus) { this.checkRoomSocketStatus = false }
      let userChatListing = '#userChatListingId' + userInfo.id;
      $('.chat_user_listed').removeClass('active');
      $(userChatListing).addClass('active');
      if (this.selectedRoomIdForChatStart != userInfo.id) {
        if(this.TypingEventForSelectedUserId) {
          videoSocketRef.emit({
            type: "TypingEventForSelectedUser",
            message: 'stop',
            selfUserID: this.selfUserId,
            targetForChatUserId: this.TypingEventForSelectedUserId,
            second_user_id:this.TypingEventForSelectedUserId
          })
          videoSocketRef.emit({
            type: "TypingEventForSelectedGroup",
            message: 'stop',
            selfUserID: this.selfUserId,
            targetForChatUserId: this.TypingEventForSelectedUserId,
            chatRoomId:this.TypingEventForSelectedUserId,
            second_user_id:this.TypingEventForSelectedUserId
          })
        }
        this.msgNextPageLoader = ''
        this.selectedChatFromUserDetails = userInfo.user
        this.TypingEventForSelectedUserId = this.selectedChatFromUserDetails.id
        this.selectedRoomIdForChatStart = userInfo.id
        this.selectedRoomOfChatUpperInfo.chat_room = userInfo.id
        this.submitted = true
        this.userChatContent = {}
        this.chatAllListResults = []
        this.chatRepeatedDateList = []
        $('.live_msg').remove();
        var msgBoxContentEmpty = document.getElementById('list_of_chat_msg_wrp')
          msgBoxContentEmpty.innerHTML = "";
        let data = {
          users: [this.selfUserId, this.selectedChatFromUserDetails.id],
          is_support_chat: false,
          ngo_id:this.$route.params.Id
        }
        this.$store.dispatch('createChatRoomForPatientMethod', { data: { id: this.selfUserId, data: data } }).then((res) => {
          var createdRoomChatId =  res.data.data.id
          let chatForSelectedUserId = this.selectedChatFromUserDetails.id
          this.roomChatIdForFile = createdRoomChatId
          this.roomChatId = createdRoomChatId.replace(/-/g, "")
          this.$store.dispatch('getNgoMemberChatRoomInfoByIdFunc', { id: createdRoomChatId }).then((response) => {
          this.userChatContent = response.data.data.data
          this.chatAllListResults = [].concat(response.data.data.data.results)
          this.chatRepeatedDateList = [].concat(response.data.data.data.repeated_dates_arr)
          this.msgNextPageLoader = response.data.data.data.next
          setTimeout(() => {
            var userMsgReadStatus;
            userMsgReadStatus = this.totalChatListOfArray.find(item => {
              if(item.user){
               return item.user.id == chatForSelectedUserId
              }
            });
            if(userMsgReadStatus){
              userMsgReadStatus.unread_count = 0
            }
          }, 500)
          setTimeout(() => {
            var __self = this
            var timeout;
            var elmnt = document.getElementById("chat_msg_list_scroll");
            var y = elmnt.scrollHeight;
            $('#chat_msg_list_scroll').animate({ scrollTop: y }, 20)
            // Message Send Event Will Code Here
            document.querySelector('#chat-message-reply-input').addEventListener('keydown', event => {
              if (event.key === 'PageUp' || event.key === 'PageDown') {
                const cursorPosition = event.key === 'PageUp' ? 0 : event.target.textLength;
                event.preventDefault();
                event.target.setSelectionRange(cursorPosition, cursorPosition);
              }
            });
            document.querySelector('#chat-message-reply-input').onkeyup = function (event) {
              var messageInputDom = document.querySelector('#chat-message-reply-input');
              __self.msgValue = messageInputDom.value;
              if(__self.selection_type == 'press_enter') {
                __self.msgValue = messageInputDom.value;
                if (event.keyCode === 13) {
                  if (event.shiftKey) {
                    event.stopPropagation();
                  } else if (!event.shiftKey) {
                    event.preventDefault();
                    document.querySelector('#chat-message-reply-submit').click();
                  }
                }else {
                  if (messageInputDom.value.length > 0) {
                    videoSocketRef.emit({
                      type: "TypingEventForSelectedUser",
                      message: 'typing',
                      selfUserID: __self.selfUserId,
                      targetForChatUserId:__self.selectedChatFromUserDetails.id,
                      second_user_id:__self.selectedChatFromUserDetails.id
                    });
                    clearTimeout(timeout)
                    timeout = setTimeout(() => {
                      videoSocketRef.emit({
                        type: "TypingEventForSelectedUser",
                        message: 'stop',
                        selfUserID: __self.selfUserId,
                        targetForChatUserId: __self.selectedChatFromUserDetails.id,
                        second_user_id:__self.selectedChatFromUserDetails.id
                      })
                    }, 2000)
                  }
                }
              }
            };
            document.querySelector('#chat-message-reply-input').focus();
            document.querySelector('#chat-message-reply-submit').onclick = function (e) {
              var messageInputDom = document.querySelector('#chat-message-reply-input');
              __self.msgValue = messageInputDom.value;
              var message = __self.msgValue;
              var msg = message.replace(/</g, "&lt;").replace(/>/g, "&gt;").trim();
              if (msg !== "") {
                var sentUserInfo = __self.selectedChatFromUserDetails;
                let sentData = {
                  'message': message,
                  type: 'sent',
                  email: sentUserInfo.email,
                  user_id: sentUserInfo.id,
                  name: sentUserInfo.first_name + ' ' + sentUserInfo.last_name,
                  selfUserID: __self.selfUserId,
                  is_read: false,
                }
                const currentUserId = localStorage.getItem('LoginedUserID');
                const payload = {
                  toUserID: sentUserInfo.id,
                  second_user_id:sentUserInfo.id,
                  fromUserID: currentUserId,
                  chatRoomId: createdRoomChatId,
                  senderMsgData: message,
                  msgSenderFullName: __self.selfUserInfo.first_name + ' ' + __self.selfUserInfo.last_name
                };
                videoSocketRef.emit({
                  type: "TypingEventForSelectedUser",
                  message: 'stop',
                  selfUserID: __self.selfUserId,
                  targetForChatUserId: __self.selectedChatFromUserDetails.id,
                  second_user_id:__self.selectedChatFromUserDetails.id
                })
                clearTimeout(timeout)
                if (__self.replyMsgForSeletedId) {
                  socketCheck.send(JSON.stringify({
                    'message': message, 'user': sentUserInfo.id,
                    'reply_to_msg': __self.replyMsgForSeletedId, 'chat_data': sentData
                  }));
                } else {
                  socketCheck.send(JSON.stringify({ 'message': message, 'user': sentUserInfo.id, 'chat_data': sentData }));
                }
                let socketTimeout = setTimeout(function () {
                  bus.$emit('newChatMessage', payload);
                  clearTimeout(socketTimeout);
                }, 2000);
                messageInputDom.value = '';
                __self.msgValue = ''
                __self.messageContentDraftValue = ''
              }
              var elmnt = document.getElementById("chat_msg_list_scroll");
              var y = elmnt.scrollHeight;
              $('#chat_msg_list_scroll').scrollTop(y)
              _self.cancelReplyMessageOnChatMethod();
              _self.moveToTopIndexSelectedUserOfChatListMethod(createdRoomChatId)
            };
          }, 200)
          if (this.userChatContent.last_message) {
            this.lastMessageReadOfMethod(this.userChatContent.last_message.id, this.roomChatId)
          }
        })
        // Web Socket Closed Method Here
        if (this.checkSocketId) {
          if (this.roomChatId != this.checkSocketId) {
            this.checkRoomSocketStatus = true
            this.socketCheck.close();
          }
        }
        // Web Socket Started Method Here
        if (this.roomChatId != this.checkSocketId) {
          var _self = this
          var socketCheck = new WebSocket(
            'wss://' + process.env.VUE_APP_WEBSOCKET_BASE_URL +
            '/ws/patient-chat/' + _self.roomChatId + '/'
          );
          setTimeout(() => {
            if (videoSocketRef.isOpen()) {
              videoSocketRef.emit({
                type: "messageIndicationForChat",
                selfUserID: _self.selfUserId,
                targetForChatUserId: _self.selectedChatFromUserDetails.id,
                second_user_id: _self.selectedChatFromUserDetails.id
              });
            }
          }, 1000)
          _self.checkSocketId = _self.roomChatId
          _self.socketCheck = socketCheck
          socketCheck.onmessage = function (e) {
            _self.userToUserMsgDisplayMethod(e)
          };
          socketCheck.onclose = function (e) {
            console.error('Socket Closed')
            if (_self.checkRoomSocketStatus == false) {
              setTimeout(() => {
                socketCheck = new WebSocket(
                  'wss://' + process.env.VUE_APP_WEBSOCKET_BASE_URL +
                  '/ws/patient-chat/' + _self.roomChatId + '/'
                );
                socketCheck.onmessage = function (e) {
                  _self.userToUserMsgDisplayMethod(e)
                };
              }, 1000)
            } else {
            }
          };
        }
        })
      }
    },
    userToUserMsgDisplayMethod(e){
      var _self = this
      var messagesList = document.getElementById('reply_msg_list')
      var checkSelectedUserIdOfChat = this.selectedChatFromUserDetails.id
      var data = JSON.parse(e.data);
      var message = JSON.parse(data.message);
      var li = document.createElement("div");
      var div = document.createElement("div");
      var p = document.createElement("div");
      var a = document.createElement("a");
      var msg_link = document.createElement("span");
      var fileName = document.createElement("a");
      var img = document.createElement("img");
      var span = document.createElement("span");
      var span2 = document.createElement("span");
      var time_wrp = document.createElement("span");
      var time = document.createElement("time");
      let dateTimeAdd = [moment(message.chat_data.current_time).format('h:mm a')];
      time.appendChild(document.createTextNode(dateTimeAdd));
      time.setAttribute("class", "msg_time");
      time_wrp.setAttribute("class", 'msg_time_wrp');
      li.setAttribute("class", 'live_msg');
      li.appendChild(div);
      p.setAttribute("class", 'chat_in_msg_box_wrp');
      var dropdownDiv = document.createElement("div");
      var msg_toggle_option = document.createElement("span");
      var fa_chevron_down = document.createElement("i");
      var dropdown_menu = document.createElement("ul");
      var reply_li = document.createElement("li");
      var delete_li = document.createElement("li");
      var forwardedMessage_li = document.createElement("li");
      dropdownDiv.setAttribute("class", 'dropdown');
      msg_toggle_option.setAttribute("class", 'msg_toggle_option');
      msg_toggle_option.setAttribute("data-toggle", 'dropdown');
      fa_chevron_down.setAttribute("class", 'fa fa-chevron-down');
      dropdown_menu.setAttribute("class", 'dropdown-menu');
      delete_li.onclick = function () {
        _self.deteteMessageFromChatMethod(message.chat_data.message_id, message.chat_data.message, message.chat_data.current_time, message.chat_data.selfUserID)
      }
      reply_li.onclick = function() {
        _self.replyMessageOnChatMethod('userChat', message, 'live')
      }
      forwardedMessage_li.onclick = function() {
        _self.forwardMsgToUserMethod(message)
      }
      // dropdownDiv.appendChild(msg_toggle_option);
      // msg_toggle_option.appendChild(fa_chevron_down);
      // msg_toggle_option.after(dropdown_menu);
      // dropdown_menu.appendChild(reply_li);
      // dropdown_menu.appendChild(forwardedMessage_li);
      // dropdown_menu.appendChild(delete_li);
      // reply_li.appendChild(document.createTextNode('Reply'));
      // delete_li.appendChild(document.createTextNode('Delete Message'));
      // forwardedMessage_li.appendChild(document.createTextNode('Forward Message'));
      if (message.reply_to_msg) {
        var show_msg_of_reply_wrp = document.createElement("span");
        var show_msg_of_reply_name = document.createElement("span");
        show_msg_of_reply_name.style.color = message.chat_data.colour
        show_msg_of_reply_wrp.style.borderLeftColor = message.chat_data.colour
        var show_msg_of_reply_txt = document.createElement("span");
        show_msg_of_reply_wrp.setAttribute("class", 'show_msg_of_reply_wrp');
        show_msg_of_reply_name.setAttribute("class", 'show_msg_of_reply_name');
        show_msg_of_reply_txt.setAttribute("class", 'show_msg_of_reply_txt');
        show_msg_of_reply_name.appendChild(document.createTextNode(message.reply_to_msg.data.sender_user_detail.first_name + ' ' + message.reply_to_msg.data.sender_user_detail.last_name));
        if(message.reply_to_msg.data.file_is){
          show_msg_of_reply_txt.appendChild(document.createTextNode(message.reply_to_msg.data.filename))
        }else{
          show_msg_of_reply_txt.appendChild(document.createTextNode(message.reply_to_msg.data.message))
        }
        show_msg_of_reply_wrp.appendChild(show_msg_of_reply_name);
        show_msg_of_reply_name.after(show_msg_of_reply_txt);
        p.appendChild(show_msg_of_reply_wrp);
      }
      p.appendChild(dropdownDiv);
      li.setAttribute("id", 'chatMessageId' + message.chat_data.message_id);
      if (message.chat_data.is_file) {
        div.setAttribute("id", 'live_msg_in_file_recieved');
        // var msgExt = message.message.split(".").slice(-1)[0].substr(0, 3)
        var msgExt = message.message.substr((message.message.lastIndexOf('.') + 1));
        msgExt = msgExt.substring(0, msgExt.lastIndexOf("?"))
        li.appendChild(div);
        div.appendChild(p);
        p.appendChild(span);
        span.setAttribute("class", 'msg_in_file');
        if (msgExt == 'PNG' || msgExt == 'png' || msgExt == 'jpeg' || msgExt == 'jpg' || msgExt == 'svg' || msgExt == 'gif' || msgExt == 'webp') {
          img.setAttribute("src", message.message);
          var msg_img_render_in_chat = document.createElement("span");
          msg_img_render_in_chat.setAttribute("class", 'msg_img_render_in_chat');
          span.appendChild(msg_img_render_in_chat);
          msg_img_render_in_chat.appendChild(a);
          a.setAttribute("href", message.message);
          a.setAttribute("target", "_new");
          a.appendChild(img);
          img.setAttribute("class", 'img-fluid');
        } else {
          img.setAttribute("src", _self.checkFileExtensionForChatMessage(message.message));
          var msg_file_render_in_chat = document.createElement("span");
          msg_file_render_in_chat.setAttribute("class", 'msg_file_render_in_chat');
          span.appendChild(msg_file_render_in_chat);
          msg_file_render_in_chat.appendChild(a);
          a.setAttribute("href", message.message);
          a.setAttribute("target", "_new");
          a.appendChild(img);
          img.setAttribute("class", 'img-fluid');
          var msg_file_name = document.createElement("span");
          msg_file_name.setAttribute("class", 'msg_file_name');
          msg_file_name.appendChild(document.createTextNode(message.message.split('/').slice(-1)[0].split('?')[0]));
          img.after(msg_file_name)
        }
        if (message.chat_data.message) {
          fileName.appendChild(document.createTextNode(message.chat_data.message));
          fileName.setAttribute("href", message.message);
          fileName.setAttribute("class", 'msg_link');
          span.after(fileName);
        }
        span2.appendChild(time);
        span2.setAttribute("class", 'msg_time_wrp');
        p.appendChild(span2);
      } else {
        if (message.chat_data.message.split(':/')[0] == 'http' || message.chat_data.message.split(':/')[0] == 'https') {
          a.setAttribute("href", message.chat_data.message);
          a.setAttribute("target", "_new");
          a.setAttribute("class", 'hyper_link');
          a.appendChild(document.createTextNode(message.chat_data.message));
          p.appendChild(a);
          div.appendChild(p);
          time_wrp.appendChild(time);
          p.appendChild(time_wrp);
        } else {
          a.setAttribute("href", "javascript:void(0)");
          msg_link.setAttribute("class", 'msg_link');
          msg_link.appendChild(document.createTextNode(message.chat_data.message));
          p.appendChild(msg_link);
          div.appendChild(p);
          time_wrp.appendChild(time);
          p.appendChild(time_wrp);
        }
      }
      if (message.chat_data.user_id == checkSelectedUserIdOfChat) {
        var labelReadS = document.createElement("label")
        var labelReadI = document.createElement("i")
        labelReadS.setAttribute("class", 'chat_msg_send_received_action_wrp');
        if (message.chat_data.is_read) {
          labelReadI.setAttribute("class", 'msg_indication msg_read_ind');
        } else {
          if (message.chat_data.read_status_value == 'sent') {
            labelReadI.setAttribute("class", 'msg_indication msg_sent_ind');
          } else {
            labelReadI.setAttribute("class", 'msg_indication msg_unsent_ind')
          }
        }
        labelReadS.appendChild(labelReadI);
        time.after(labelReadS);
        div.setAttribute("class", 'recieved');
      } else {
        div.setAttribute("class", 'sent');
        _self.lastMessageReadOfMethod(message.chat_data.message_id,  _self.roomChatId)
      }
      messagesList.appendChild(li);
      var elmnt = document.getElementById("chat_msg_list_scroll");
      var y = elmnt.scrollHeight;
      $('#chat_msg_list_scroll').scrollTop(y)
    },
    /*
    * This Will File Sent In Chat Method Here
    */
    /*
    * This Will Group Chat Room Created With Selected user Method Here
    */
    getUserDetailsWithGroupChatFun(groupId, groupChatDetails) {
      if(this.isMobile){this.showChatInfoInMBlMethod()}
      this.messageContentDraftValue = ''
      this.cancelReplyMessageOnChatMethod()
      if(this.checkRoomSocketStatus){
        this.checkRoomSocketStatus = false
      }
      let userChatListing = '#userChatListingId' + groupId;
      $('.chat_user_listed').removeClass('active');
      $(userChatListing).addClass('active');
      this.selectedChatFromUserDetails = groupChatDetails
      if(this.selectedRoomIdForChatStart != groupId) {
        if(this.TypingEventForSelectedUserId) {
          videoSocketRef.emit({
            type: "TypingEventForSelectedUser",
            message: 'stop',
            selfUserID: this.selfUserId,
            targetForChatUserId: this.TypingEventForSelectedUserId,
            second_user_id:this.TypingEventForSelectedUserId
          })
          videoSocketRef.emit({
            type: "TypingEventForSelectedGroup",
            message: 'stop',
            selfUserID: this.selfUserId,
            targetForChatUserId: this.TypingEventForSelectedUserId,
            chatRoomId:this.TypingEventForSelectedUserId,
            second_user_id:this.TypingEventForSelectedUserId
          })
        }
        this.TypingEventForSelectedUserId = groupId
        this.roomChatId = ''
        this.msgNextPageLoader = ''
        this.selectedRoomIdForChatStart = groupId
        this.roomChatId = groupId.replace(/-/g, "")
        this.submitted = true
        this.userChatContent = {}
        this.chatAllListResults = []
        this.chatRepeatedDateList = []
        $('.live_msg').remove();
        var msgBoxContentEmpty = document.getElementById('list_of_chat_msg_wrp')
        msgBoxContentEmpty.innerHTML = "";
        this.$store.dispatch('getNgoGroupInfoByIdFunc', { id: this.selectedRoomIdForChatStart }).then((response) => {
          this.userChatContent = response.data.data.data
          this.chatAllListResults = [].concat(response.data.data.data.results)
          this.chatRepeatedDateList = [].concat(response.data.data.data.repeated_dates_arr)
          this.msgNextPageLoader = response.data.data.data.next
          setTimeout(() => {
            var groupMsgReadStatus;
            if(groupChatDetails.pinned){
              groupMsgReadStatus = this.pinnedChatRoomList.find(item => item.id == this.selectedRoomIdForChatStart);
            }else{
             groupMsgReadStatus = this.getGroupRoomListDataResults.find(item => item.id == this.selectedRoomIdForChatStart);
            }
            if(groupMsgReadStatus){
              groupMsgReadStatus.unread_count = 0
            }
          }, 500)
          $('.sent_list').remove()
          $('.recieved_list').remove()
          var __self = this
          setTimeout(() => {
            var timeout;
            var elmnt = document.getElementById("chat_msg_list_scroll");
            var y = elmnt.scrollHeight;
            $('#chat_msg_list_scroll').animate({ scrollTop: y }, 20)
            // Message Send Event Will Code Here
            document.querySelector('#chat-message-reply-input').addEventListener('keydown', event => {
              if (event.key === 'PageUp' || event.key === 'PageDown') {
                const cursorPosition = event.key === 'PageUp' ? 0 : event.target.textLength;
            
                event.preventDefault();
                event.target.setSelectionRange(cursorPosition, cursorPosition);
              }
            });
            document.querySelector('#chat-message-reply-input').onkeyup = function (event) {
              var messageInputDom = document.querySelector('#chat-message-reply-input');
              if(__self.messageContentDraftValue.length >= 1){
                if(__self.messageContentDraftValue.split('').slice(-1) == '@' || event.key == '@'){
                  __self.suggestionUserListState = true
                }
              }else{
                __self.suggestionUserListState = false
              }
              if (__self.selection_type == 'press_enter') {
                __self.msgValue = messageInputDom.value;
                if (event.keyCode === 13) {
                  if (event.shiftKey) {
                    event.stopPropagation();
                  } else if (!event.shiftKey) {
                    event.preventDefault();
                    document.querySelector('#chat-message-reply-submit').click();
                  }
                } else {
                  if (messageInputDom.value.length > 0) {
                    videoSocketRef.emit({
                      type: "TypingEventForSelectedGroup",
                      message: 'typing',
                      selfUserID: _self.selfUserId,
                      targetForChatUserId: _self.selectedRoomIdForChatStart,
                      ingroupusers: _self.selectedChatFromUserDetails.users,
                      chatRoomId:_self.selectedRoomIdForChatStart,
                      second_user_id:_self.selectedRoomIdForChatStart
                    });
                    clearTimeout(timeout)
                    timeout = setTimeout(() => {
                      videoSocketRef.emit({
                        type: "TypingEventForSelectedGroup",
                        message: 'stop',
                        selfUserID: _self.selfUserId,
                        targetForChatUserId: _self.selectedRoomIdForChatStart,
                        chatRoomId:_self.selectedRoomIdForChatStart,
                        second_user_id:_self.selectedRoomIdForChatStart
                      })
                    }, 2000)
                  }
                }
              }
            };
            document.querySelector('#chat-message-reply-input').focus();
            document.querySelector('#chat-message-reply-submit').onclick = function (e) {
              var messageInputDom = document.querySelector('#chat-message-reply-input');
              __self.msgValue = messageInputDom.value;
              var message = __self.msgValue;
              var msg = message.replace(/</g, "&lt;").replace(/>/g, "&gt;").trim();
              if (msg !== "") {
                let sentData = {
                  'message': message,
                  type: 'sent',
                  email: __self.selfUserInfo.email,
                  user_id: __self.selfUserInfo.id,
                  name: __self.selfUserInfo.first_name + ' ' + __self.selfUserInfo.last_name
                }
                const currentUserId = localStorage.getItem('LoginedUserID');
                const payload = {
                  selfUserID: currentUserId,
                  chatRoomId: __self.selectedRoomIdForChatStart,
                  second_user_id: __self.selectedRoomIdForChatStart,
                  senderMsgData: __self.selfUserInfo.first_name + ' ' + __self.selfUserInfo.last_name + ' : ' + message,
                  msgSenderFullName: __self.selectedChatFromUserDetails.name
                };
                videoSocketRef.emit({
                  type: "TypingEventForSelectedGroup",
                  message: 'stop',
                  selfUserID: _self.selfUserId,
                  targetForChatUserId: _self.selectedRoomIdForChatStart,
                  chatRoomId:_self.selectedRoomIdForChatStart,
                  second_user_id:_self.selectedRoomIdForChatStart
                })
                clearTimeout(timeout)
                if (__self.replyMsgForSeletedId) {
                  socketCheck.send(JSON.stringify({ 'message': message, 'user': currentUserId, 'reply_to_msg': __self.replyMsgForSeletedId, 'chat_data': sentData }));
                } else {
                  socketCheck.send(JSON.stringify({ 'message': message, 'user': currentUserId, 'chat_data': sentData }));
                }
                let socketTimeout = setTimeout(function () {
                  bus.$emit('newChatMessageOfGroupRoom', payload);
                  clearTimeout(socketTimeout);
                }, 2500);
                messageInputDom.value = '';
                __self.msgValue = ''
                __self.messageContentDraftValue = ''
              }
              var elmnt = document.getElementById("chat_msg_list_scroll");
              var y = elmnt.scrollHeight;
              $('#chat_msg_list_scroll').scrollTop(y)
              __self.cancelReplyMessageOnChatMethod()
              if(!groupChatDetails.pinned){
                __self.moveToTopIndexSelectedGroupOfChatListMethod(groupId)
              }
            };
            
          }, 200)
          if (this.userChatContent.last_message) {
            this.lastMessageReadOfMethod(this.userChatContent.last_message.id, this.roomChatId)
          }
        }).catch((err) => {
        })
        // Search User List For Chat Code Here
        if (this.userSearchListTabs == true) {
          this.$store.dispatch('getAllLeftChatListDetailsFun').then((res) => {
            this.totalChatListOfData = res.data.data
            $('.chat_user_listed').removeClass('active');
            let userChatListing = '#userChatListingId' + groupId;
            setTimeout(() => {
              $(userChatListing).addClass('active');
            }, 100);
            this.searchBarForChatClosedFun()
          })
        }
        // Web Socket Closed Method Here
        if (this.checkSocketId) {
          if (this.roomChatId != this.checkSocketId) {
            this.checkRoomSocketStatus = true
            this.socketCheck.close();
          }
        }
        // Web Socket Started Method Here
        if (this.roomChatId != this.checkSocketId) {
          var _self = this
          var socketCheck = new WebSocket(
            'wss://' + process.env.VUE_APP_WEBSOCKET_BASE_URL +
            '/ws/group-chat/' + this.roomChatId + '/'
          );
          setTimeout(() => {
            if (videoSocketRef.isOpen()) {
              videoSocketRef.emit({
                type: "messageIndicationForChat",
                selfUserID: _self.selfUserId,
                targetForChatUserId: _self.selectedChatFromUserDetails.id,
                second_user_id: _self.selectedChatFromUserDetails.id,
                ingroupusers: _self.selectedChatFromUserDetails.users,
              });
            }
          }, 1000)
          this.checkSocketId = socketCheck.url.split('/')[5]
          this.socketCheck = socketCheck
          socketCheck.onmessage = function (e) {
            _self.groupMsgDisplayMethod(e)
          };
          socketCheck.onclose = function (e) {
            if (_self.checkRoomSocketStatus == false) {
              setTimeout(() => {
                // console.log(_self.checkRoomSocketStatus, _self.roomChatId, "Socket Reconnecting For Group Chat")
                socketCheck = new WebSocket(
                  'wss://' + process.env.VUE_APP_WEBSOCKET_BASE_URL +
                  '/ws/group-chat/' + _self.roomChatId + '/'
                );
                socketCheck.onmessage = function (e) {
                  _self.groupMsgDisplayMethod(e)
                };
              }, 1000)
            } else {
              // console.log(_self.checkRoomSocketStatus, "Socket Closed manually testing")
            }
          };
        }
      }
    },
    groupMsgDisplayMethod(e){
      var messagesList = document.getElementById('reply_msg_list')
      var _self = this
      if (_self.selfUserInfo) {
        var currentUserId = _self.selfUserInfo.id
      }
      var data = JSON.parse(e.data);
      var message = JSON.parse(data.message);
      var li = document.createElement("div");
      var div = document.createElement("div");
      var p = document.createElement("div");
      var a = document.createElement("a");
      var fileName = document.createElement("a");
      var img = document.createElement("img");
      var span = document.createElement("span");
      var span2 = document.createElement("span");
      var sender_name = document.createElement("span");
      var time_wrp = document.createElement("span");
      var time = document.createElement("time");
      let dateTimeAdd = [moment(message.chat_data.current_time).format('h:mm a')];
      time.appendChild(document.createTextNode(dateTimeAdd));
      time.setAttribute("class", "msg_time");
      sender_name.setAttribute("class", "message_sender_name");
      sender_name.style.color = message.chat_data.colour
      time_wrp.setAttribute("class", 'msg_time_wrp');
      li.setAttribute("class", 'live_msg');
      li.appendChild(div);
      p.setAttribute("class", 'chat_in_msg_box_wrp');
      var dropdownDiv = document.createElement("div");
      var msg_toggle_option = document.createElement("span");
      var fa_chevron_down = document.createElement("i");
      var dropdown_menu = document.createElement("ul");
      var reply_li = document.createElement("li");
      var delete_li = document.createElement("li");
      var forwardedMessage_li = document.createElement("li");
      dropdownDiv.setAttribute("class", 'dropdown');
      msg_toggle_option.setAttribute("class", 'msg_toggle_option');
      msg_toggle_option.setAttribute("data-toggle", 'dropdown');
      fa_chevron_down.setAttribute("class", 'fa fa-chevron-down');
      dropdown_menu.setAttribute("class", 'dropdown-menu');
      delete_li.onclick = function () {
        _self.deteteMessageFromChatMethod(message.chat_data.message_id, message.chat_data.message, message.chat_data.current_time, message.chat_data.selfUserID)
      }
      reply_li.onclick = function() {
        _self.replyMessageOnChatMethod('groupChat', message, 'live')
      }
      forwardedMessage_li.onclick = function() {
        _self.forwardMsgToUserMethod(message)
      }
      dropdownDiv.appendChild(msg_toggle_option);
      msg_toggle_option.appendChild(fa_chevron_down);
      msg_toggle_option.after(dropdown_menu);
      dropdown_menu.appendChild(reply_li);
      dropdown_menu.appendChild(forwardedMessage_li);
      dropdown_menu.appendChild(delete_li);
      reply_li.appendChild(document.createTextNode('Reply'));
      delete_li.appendChild(document.createTextNode('Delete Message'));
      forwardedMessage_li.appendChild(document.createTextNode('Forward Message'));
      if (message.reply_to_msg) {
        var show_msg_of_reply_wrp = document.createElement("span");
        var show_msg_of_reply_name = document.createElement("span");
        show_msg_of_reply_name.style.color = message.chat_data.colour
        show_msg_of_reply_wrp.style.borderLeftColor = message.chat_data.colour
        var show_msg_of_reply_txt = document.createElement("span");
        show_msg_of_reply_wrp.setAttribute("class", 'show_msg_of_reply_wrp');
        show_msg_of_reply_name.setAttribute("class", 'show_msg_of_reply_name');
        show_msg_of_reply_txt.setAttribute("class", 'show_msg_of_reply_txt');
        show_msg_of_reply_name.appendChild(document.createTextNode(message.reply_to_msg.data.name));
        if(message.reply_to_msg.data.file_is){
          show_msg_of_reply_txt.appendChild(document.createTextNode(message.reply_to_msg.data.filename))
        }else{
          show_msg_of_reply_txt.appendChild(document.createTextNode(message.reply_to_msg.data.message))
        }
        show_msg_of_reply_wrp.appendChild(show_msg_of_reply_name);
        show_msg_of_reply_name.after(show_msg_of_reply_txt);
        p.appendChild(show_msg_of_reply_wrp);
      }
      p.appendChild(dropdownDiv);
      li.setAttribute("id", 'chatMessageId' + message.chat_data.message_id);
      if (message.chat_data.is_file) {
        div.setAttribute("id", 'live_msg_in_file_recieved');
        var msgExt = message.message.substr((message.message.lastIndexOf('.') + 1));
        msgExt = msgExt.substring(0, msgExt.lastIndexOf("?"))
        li.appendChild(div);
        div.appendChild(p);
        p.appendChild(span);
        span.setAttribute("class", 'msg_in_file');
        if (msgExt == 'PNG' || msgExt == 'png' || msgExt == 'jpeg' || msgExt == 'jpg' || msgExt == 'svg' || msgExt == 'gif' || msgExt == 'webp') {
          img.setAttribute("src", message.message);
          var msg_img_render_in_chat = document.createElement("span");
          msg_img_render_in_chat.setAttribute("class", 'msg_img_render_in_chat');
          span.appendChild(msg_img_render_in_chat);
          msg_img_render_in_chat.appendChild(a);
          a.setAttribute("href", message.message);
          a.setAttribute("target", "_new");
          a.appendChild(img);
          img.setAttribute("class", 'img-fluid');
        } else {
          img.setAttribute("src", _self.checkFileExtensionForChatMessage(message.message));
          var msg_file_render_in_chat = document.createElement("span");
          msg_file_render_in_chat.setAttribute("class", 'msg_file_render_in_chat');
          span.appendChild(msg_file_render_in_chat);
          msg_file_render_in_chat.appendChild(a);
          a.setAttribute("href", message.message);
          a.setAttribute("target", "_new");
          a.appendChild(img);
          img.setAttribute("class", 'img-fluid');
          var msg_file_name = document.createElement("span");
          msg_file_name.setAttribute("class", 'msg_file_name');
          msg_file_name.appendChild(document.createTextNode(message.message.split('/').slice(-1)[0].split('?')[0]));
          img.after(msg_file_name)
        }
        if (message.chat_data.message) {
          fileName.appendChild(document.createRange().createContextualFragment(message.chat_data.message));
          fileName.setAttribute("href", message.message);
          fileName.setAttribute("class", 'msg_link');
          span.after(fileName);
        }
        span2.appendChild(time);
        span2.setAttribute("class", 'msg_time_wrp');
        p.appendChild(span2);
      } else {
        if (message.chat_data.message.split(':/')[0] == 'http' || message.chat_data.message.split(':/')[0] == 'https') {
          a.setAttribute("href", message.chat_data.message);
          a.setAttribute("target", "_new");
          a.setAttribute("class", 'hyper_link');
          a.appendChild(document.createTextNode(message.chat_data.message));
          p.appendChild(a);
          div.appendChild(p);
          time_wrp.appendChild(time);
          p.appendChild(time_wrp);
        } else {
          a.setAttribute("href", "javascript:void(0)");
          a.setAttribute("class", 'msg_link_chat');
          a.appendChild(document.createRange().createContextualFragment(message.chat_data.message));
          p.appendChild(a);
          div.appendChild(p);
          time_wrp.appendChild(time);
          p.appendChild(time_wrp);
        }
      }
      if (message.chat_data.user_id == currentUserId) {
        var labelReadS = document.createElement("label")
        var labelReadI = document.createElement("i")
        labelReadS.setAttribute("class", 'chat_msg_send_received_action_wrp');
        if (message.chat_data.is_group_read) {
          labelReadI.setAttribute("class", 'msg_indication msg_read_ind');
        } else {
          if (message.chat_data.read_status_value == 'sent') {
            labelReadI.setAttribute("class", 'msg_indication msg_sent_ind');
          } else {
            labelReadI.setAttribute("class", 'msg_indication msg_unsent_ind')
          }
        }
        labelReadS.appendChild(labelReadI);
        time.after(labelReadS);
        div.setAttribute("class", 'recieved');
      }else{
        sender_name.appendChild(document.createTextNode(message.chat_data.name));
        p.prepend(sender_name)
        div.setAttribute("class", 'sent');
        _self.lastMessageReadOfMethod(message.chat_data.message_id,  _self.roomChatId)
      }
      messagesList.appendChild(li);
      var elmnt = document.getElementById("chat_msg_list_scroll");
      var y = elmnt.scrollHeight;
      $('#chat_msg_list_scroll').scrollTop(y)
    },
    /*
    * This Will Searching User For Chat Method Here
    */
    onInputClick(event) {
      event.target.value = ''
    },
    handleFileUpload(event) {
      this.imgSrc = ''
      if (event.target.files && event.target.files.length > 0) {
        this.uploadFile = event.target.files[0];
        this.filename = this.uploadFile.name
        if (event.target.files[0].type.split('/')[0] == 'image') {
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
              this.imgSrc = event.target.result;
            };
            reader.readAsDataURL(this.uploadFile);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        }
      }
    },
    textareaInputWithFileMethod(e){
      document.querySelector('#textarea_for_file_input').addEventListener('keydown', event => {
        if (event.key === 'PageUp' || event.key === 'PageDown') {
          const cursorPosition = event.key === 'PageUp' ? 0 : event.target.textLength;
          event.preventDefault();
          event.target.setSelectionRange(cursorPosition, cursorPosition);
        }
      });
      if(this.fileMessage.length >= 1){
        if(this.fileMessage.split('').slice(-1) == '@' || e.key == '@'){
          this.suggestionUserListStateForFileAttach = true
        }
      }else{
        this.suggestionUserListStateForFileAttach = false
      }
    },
    cancelUploadFile() {
      this.uploadFile = null;
      this.filename = '';
      this.fileMessage = ''
      this.messageContentDraftValue = ''
    },
    /*
    * This Will Check File Extension In Chat
    */
    checkFileExtensionForChatMessage(file) {
      let extension = file.substr((file.lastIndexOf('.') + 1));
      extension = extension.substring(0, extension.lastIndexOf("?"));
      switch (extension) {
        case 'zip':
          return require('../../../assets/images/filetype/zip.png')
          break;
        case 'rar':
          return require('../../../assets/images/filetype/zip-1.png');
          break;
          break;
        case 'eps':
          return require('../../../assets/images/filetype/ai.png')
          break;
        case 'pdf':
          return require('../../../assets/images/filetype/pdf.png')
          break;
        case 'css':
          return require('../../../assets/images/filetype/css.png')
          break;
        case 'csv':
          return require('../../../assets/images/filetype/csv.png')
          break;
        case 'doc':
          return require('../../../assets/images/filetype/doc.png')
          break;
        case 'docx':
          return require('../../../assets/images/filetype/doc.png')
          break;
        case 'json':
          return require('../../../assets/images/filetype/json-file.png')
          break;
        case 'ppt':
          return require('../../../assets/images/filetype/ppt.png')
          break;
        case 'pptx':
          return require('../../../assets/images/filetype/ppt.png')
          break;
        case 'txt':
          return require('../../../assets/images/filetype/txt.png')
          break;
        case 'xls':
          return require('../../../assets/images/filetype/xls.png')
          break;
        case 'xlsx':
          return require('../../../assets/images/filetype/xls.png')
          break;
        case 'xml':
          return require('../../../assets/images/filetype/xml.png')
          break;
        case 'xlsm':
          return require('../../../assets/images/filetype/xls.png')
          break;
        case 'py':
          return require('../../../assets/images/filetype/python.png')
        case 'cpp':
          return require('../../../assets/images/filetype/cpp-file-format-symbol.png')
          break;
        case 'vbb':
          return require('../../../assets/images/filetype/coding.png')
          break;
        default:
          return require('../../../assets/images/filetype/file.png')
      }
    },
    /*
    * This Will Check File Extension In Chat
    */
    checkFileExtension(file) {
      let extension = file.substr((file.lastIndexOf('.') + 1));
      switch (extension) {
        case 'jpg':
          return require('../../../assets/images/filetype/jpg.png')
          break;
        case 'jpeg':
          return require('../../../assets/images/filetype/jpg.png')
          break;
        case 'png':
          return require('../../../assets/images/filetype/png.png')
          break;
        case 'gif':
          return require('../../../assets/images/filetype/jpg.png')
          break;
        case 'zip':
          return require('../../../assets/images/filetype/zip.png')
          break;
        case 'rar':
          return require('../../../assets/images/filetype/zip-1.png');
          break;
          break;
        case 'eps':
          return require('../../../assets/images/filetype/ai.png')
          break;
        case 'pdf':
          return require('../../../assets/images/filetype/pdf.png')
          break;
        case 'css':
          return require('../../../assets/images/filetype/css.png')
          break;
        case 'csv':
          return require('../../../assets/images/filetype/csv.png')
          break;
        case 'doc':
          return require('../../../assets/images/filetype/doc.png')
          break;
        case 'docx':
          return require('../../../assets/images/filetype/doc.png')
          break;
        case 'json':
          return require('../../../assets/images/filetype/json-file.png')
          break;
        case 'ppt':
          return require('../../../assets/images/filetype/ppt.png')
          break;
        case 'pptx':
          return require('../../../assets/images/filetype/ppt.png')
          break;
        case 'txt':
          return require('../../../assets/images/filetype/txt.png')
          break;
        case 'xls':
          return require('../../../assets/images/filetype/xls.png')
          break;
        case 'xlsx':
          return require('../../../assets/images/filetype/xls.png')
          break;
        case 'xml':
          return require('../../../assets/images/filetype/xml.png')
          break;
        case 'xlsm':
          return require('../../../assets/images/filetype/xls.png')
          break;
        case 'py':
          return require('../../../assets/images/filetype/python.png')
        case 'cpp':
          return require('../../../assets/images/filetype/cpp-file-format-symbol.png')
          break;
        case 'vbb':
          return require('../../../assets/images/filetype/coding.png')
          break;
        default:
          return require('../../../assets/images/filetype/file.png')
      }
    },
    /*
    * This Will Check File Submitted In MSG Method Here
    */
    fileMsgSubmit() {
      this.submitted = true
      let formData = new FormData();
      var sentUserInfo = this.selectedChatFromUserDetails;
        let sentData = {
          'message': this.fileMessage,
          type: 'sent',
          email: sentUserInfo.email,
          user_id: sentUserInfo.id,
          name: sentUserInfo.first_name + ' ' + sentUserInfo.last_name,
          file_is: true,
          selfUserID: this.selfUserId,
          filename:this.filename
        }
        formData.append('chat_room_user', this.roomChatIdForFile);
        formData.append('message', this.fileMessage);
        formData.append('file', this.uploadFile);
        formData.append('filename', this.filename);
        formData.append('data', JSON.stringify(sentData));
        this.listLoading = true
        this.$store.dispatch("uploadMsgFileInChatFun", formData).then((res) => {
          this.uploadedFileUrl = res.data.data.file
          let socketSentData = {
            'message': this.fileMessage,
            type: 'sent',
            email: sentUserInfo.email,
            user_id: sentUserInfo.id,
            name: sentUserInfo.first_name + ' ' + sentUserInfo.last_name,
            is_file: true,
            selfUserID: this.selfUserId,
            message_id: res.data.data.id
          }
          this.socketCheck.send(JSON.stringify({ 'message': this.uploadedFileUrl, 'user': sentUserInfo.id, 'chat_data': socketSentData }))
          this.$store.dispatch('getUserChatDetailsByIdFun', { id: this.roomChatIdForFile }).then((res) => {
            this.notificationForFileMessageMethod(this.fileMessage, this.filename, false)
            this.userChatContent = res.data.data
            this.chatAllListResults = [].concat(res.data.data.data.results)
            this.chatRepeatedDateList = [].concat(res.data.data.data.repeated_dates_arr)
            this.cancelUploadFile();
            document.querySelector('#chat-message-reply-input').value = ''
            $('.live_msg').remove();
            this.listLoading = false
          })
        }).catch((err) => {
          toast.error(err.data.msg);
          this.listLoading = false
        })
    },
    notificationForFileMessageMethod(message, filename){
      const currentUserId = localStorage.getItem('LoginedUserID');
      const payload = {
        toUserID: this.selectedChatFromUserDetails.id,
        second_user_id: this.selectedChatFromUserDetails.id,
        fromUserID: currentUserId,
        chatRoomId: this.selectedRoomOfChatUpperInfo.chat_room,
        senderMsgData: message ? message : filename,
        msgSenderFullName: this.selfUserInfo.first_name + ' ' + this.selfUserInfo.last_name
      };
      let socketTimeout = setTimeout(function () {
        bus.$emit('newChatMessage', payload);
        clearTimeout(socketTimeout);
      }, 1500);
    },
    fileOpenNewTab(href) {
      window.open(href, '_blank');
    },
    selectedOptionMethod(item) {
      this.selection_type = item
      this.messageFeatureStates = false
    },
    messageFeatureStatesMethod() {
      if (this.messageFeatureStates) {
        this.messageFeatureStates = false
      } else {
        this.messageFeatureStates = true
        var _self = this
        $(document).click(function (event) {
          //if you click on anything except the modal itself or the "open modal" link, close the modal
          if (!$(event.target).closest(".option_btn_wrp").length) {
            _self.messageFeatureStates = false
          }
        });
      }
    },
    replaceURLsInMsgText(message) {
      if (!message) return;
      var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      return message.replace(urlRegex, function (url) {
        var hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        return '<a href="' + hyperlink + '" class="hyper_link" target="_blank">' + url + '</a>'
      });
    },
    lastMessageReadOfMethod(ID, roomID){
      this.$store.dispatch('lastMessageReadFunc', { message_id: ID, user: roomID })
    }
  },
  watch: {
    getAllRoomChatListOfMemberFun(newVal, oldVal){
      this.totalChatListOfData = newVal.data
      if(this.totalChatListOfData){
        this.totalChatListOfArray = this.totalChatListOfData.data
      }
    },
    getAllRoomChatListOfGroupFun(newVal, oldVal) {
      this.getGroupRoomListData = newVal.data
      if(this.getGroupRoomListData){
        this.getGroupRoomListDataResults =  this.getGroupRoomListData.data
        this.listLoading = false
      }
    },
    getNGoGroupInfoByIDFun(newVal, oldVal) {
      this.getNGoInfoData = newVal.data
    },
  },
  beforeCreate: function () {
    $('body').addClass('workspace_main_page_wrp')
  },
  beforeRouteLeave: function (to, from, next) {
    clearInterval(checkChatContact)
    $('body').removeClass('workspace_main_page_wrp')
    $('#frame').removeClass('collapsed_chat_list')
    $('.slide_left_arrow').removeClass('active')
    $('.wrap .meta .name').removeClass('active')
    this.checkSocketId = ''
    if (this.socketCheck) {
      this.socketCheck.close();
    }
    this.checkRoomSocketStatus = true
    setTimeout(() => {
      if (videoSocketRef.isOpen()) {
        videoSocketRef.emit({
          type: "messageIndicationForChat",
          selfUserID: this.selfUserId,
          second_user_id: '',
          targetForChatUserId: '',
        });
      }
    }, 1000)
    next();
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, {
        passive: true
      })
    }
  },
}