/*eslint-disable */
const $ = window.jQuery = require('jquery');
import sharedNotes from "./shared-notes/index.vue";
// import notesConvertPdf from "./notes-convert-to-pdf/index.vue";
import notesView from "./notes-view-section/index.vue";
import { VueEditor } from "vue2-editor";
import {
  bus
} from '@/main';
export default {
  name: 'notes',
  components: {
    'notes-share':sharedNotes,
    'notes-view' : notesView,
    VueEditor
  },
  props: ['ngoInfo'],
  data () {
    return {
      getAllNotesList:null,
      getAllNotesListResults:[],
      notesBoardInsert:false,
      createNotesData:'',
      createNotesTitle:'',
      updateNotesField:'',
      insertedNewNotesBoard:false,
      notesRemovedPopup:false,
      notesRemovedId:'',
      verticalAllNotesListState:false,
      notesDetailsUpdatedByIDstate:false,
      notesDetailsUpdatedByIdData:'',
      sharedNotesPopup:false,
      sharedNotesInfo:'',
      sharedNotesSection:false,
      sharedNotesList:null,
      sharedNotesListResults:[],
      sharedGroupNotesListResults:[],
      sharedNotesViewDetails:{},
      sharedNotesViewSection:false,
      coreSharedNotesSection:false,
      coreSharedNotesViewSection:false,
      selfUserId:'',
      keyUpEvent:false,
      submitted:false,
      notesConvertPdfState:false,
      notesConvertPdfData:{},
      notesViewPopup:false,
      notesViewData:'',
      editorOptions: {
        placeholder: 'Description...',
        editorToolbar: ['bold','italic','link']
      },
      editorData: '',
      writeAccessSharedNotesViewSection:false,
      ngoId:''
    }
  },
  computed: {

  },
  mounted () {
    if (localStorage.getItem('LoginedUserID')) {
      this.selfUserId = localStorage.getItem('LoginedUserID')
    }
    this.ngoId = this.$route.params.Id
    this.getAllCreatedNotesListMethod()
    bus.$on('notesViewPopupBus', (data) => {
      this.notesViewPopup = data
      if(!this.notesViewPopup){
        // this.sharedNotesListMethod()
        this.getAgainAllNotesDetailsForUpdateMethod()
      }
    })
    bus.$on('sharedNotesBus', (data) => {
      this.sharedNotesPopup = data
    })
    bus.$on('notesConvertPdfStateBus', (data) => {
      this.notesConvertPdfState = data
    })
    // this.sharedNotesListMethod()
  },
  methods: {
    sharedNotesIsReadMethod(Id){
      let data = {
        user:this.selfUserId,
        grp_shared_notes:Id
      }
      this.$store.dispatch('isReadNotestStatusFun',data).then((res)=>{
        this.sharedNotesListMethod()
      })
    },
    enabledNotesViewPopupMethod(data){
      this.notesViewPopup = true
      this.notesViewData = data
      $('body').addClass('body_right_scroll_remove')
    },
    enabledConvertedPdfPopupMethod(data){
      this.notesConvertPdfState = true
      this.notesConvertPdfData = data
    },
    backToMyNotesList(){
      this.sharedNotesViewSection = false
      this.sharedNotesSection = false
    },
    backToSharedNotesList(){
      this.sharedNotesViewSection = false
    },
    backToCoreSharedNotesList(){
      this.coreSharedNotesViewSection = false
      this.insertedNewNotesBoard = true
    },
    viewCoreShareNotesInfoMethod(noteID, isRead, isReadID){
      this.submitted = true
      this.$store.dispatch('getNotesDetailsByIdFun',{id:noteID}).then((res)=>{
        this.sharedNotesViewDetails =  res.data.data
        this.insertedNewNotesBoard = false
        this.coreSharedNotesViewSection = true
        this.submitted = false
        if(isRead == false){
          this.sharedNotesIsReadMethod(isReadID)
        }
      })
    },
    viewShareNotesInfoMethod(noteID, isRead, isReadID){
      this.submitted = true
      this.$store.dispatch('getNotesDetailsByIdFun',{id:noteID}).then((res)=>{
        this.sharedNotesViewDetails =  res.data.data
        this.sharedNotesViewSection = true
        this.submitted = false
        if(isRead == false){
          this.sharedNotesIsReadMethod(isReadID)
        }
      })
    },
    writeAccessNotesInfoMethod(noteID){
      this.submitted = true
      this.$store.dispatch('getNotesDetailsByIdFun',{id:noteID}).then((res)=>{
        this.sharedNotesViewDetails =  res.data.data
        this.writeAccessSharedNotesViewSection = true
        this.submitted = false
      })
    },
    backToWriteAccessNotesList(){
      this.writeAccessSharedNotesViewSection = false
      this.sharedNotesListMethod()
      this.coreSharedNotesViewSection = false
    },
    showSharedUserListMethod(){
      if(this.sharedNotesSection == true){
        this.sharedNotesSection = false
      }else{
        this.sharedNotesSection = true
        this.sharedNotesListMethod()
      }
    },
    showSharedUserListNewMethod(){
      if(this.coreSharedNotesSection == true){
        this.coreSharedNotesSection = false
      }else{
        this.coreSharedNotesSection = true
        this.sharedNotesListMethod()
      }
    },
    sharedNotesPopupEnabledFun(data) {
      this.sharedNotesInfo = data
      this.sharedNotesPopup = true
      $('body').addClass('body_right_scroll_remove')
    },
    getAgainAllCreatedNotesListMethod(){
      this.sharedNotesSection = false
      this.$store.dispatch('getNGOUserNotesListFun', {id:this.ngoId}).then((res)=>{
        this.getAllNotesList = res.data.data
        if(this.getAllNotesList != undefined){
          this.getAllNotesListResults = this.getAllNotesList.results
          if(this.getAllNotesListResults.length == 0){
            this.verticalAllNotesListState = false
            this.notesBoardInsert = false
            this.insertedNewNotesBoard = true
          }
        }
      }).catch((err)=>{})
    },
    getAgainAllNotesDetailsForUpdateMethod(){
      this.$store.dispatch('getNGOUserNotesListFun', {id:this.ngoId}).then((res)=>{
        this.getAllNotesList = res.data.data
        if(this.getAllNotesList != undefined){
          this.getAllNotesListResults = this.getAllNotesList.results
        }
      }).catch((err)=>{})
    },
    sharedNotesListMethod(){
      this.$store.dispatch('getAllSharedNotesListFun').then((res)=>{
        this.sharedNotesList = res.data.data
        if(this.sharedNotesList != undefined){
          this.sharedNotesListResults = this.sharedNotesList.shared_notes
          this.sharedGroupNotesListResults = this.sharedNotesList.grp_shared_notes
        }
      }).catch((err)=>{})
    },
    getAllCreatedNotesListMethod(){
      this.$store.dispatch('getNGOUserNotesListFun', {id:this.ngoId}).then((res)=>{
        this.getAllNotesList = res.data.data
        if(this.getAllNotesList != undefined){
          this.getAllNotesListResults = this.getAllNotesList.results
          if(this.getAllNotesListResults.length > 0){
            this.verticalAllNotesListState = true
          }else{
            this.insertedNewNotesBoard = true
            this.notesBoardInsert = false
          }
        }
      }).catch((err)=>{})
    },
    updatedNotesDetailsByIdmethod(noteID){
      this.submitted = true
      this.$store.dispatch('getNotesDetailsByIdFun',{id:noteID}).then((res)=>{
        this.verticalAllNotesListState = false
        this.notesDetailsUpdatedByIDstate = true
        this.notesDetailsUpdatedByIdData = res.data.data
        this.submitted = false
      })
    },
    disabledAllNotesListMethod(){
      this.verticalAllNotesListState = false
    },
    enabledAllNotesListMethod(){
      this.notesDetailsUpdatedByIDstate = false
      this.verticalAllNotesListState = true
    },
    newInsertesNotesBoardMethod(){
      this.notesDetailsUpdatedByIDstate = false
      this.verticalAllNotesListState = false
      this.insertedNewNotesBoard = true
      this.notesBoardInsert = true
    },
    notesBoardInsertMethod(){
      if(this.notesBoardInsert === true){
        this.notesBoardInsert = false
      }else{
        this.notesBoardInsert = true
        this.coreSharedNotesSection = false
      }
    },
    savedInsertedNotesDataMethod(){
      if(this.createNotesData.length > 0){
        let data = {
          data:this.createNotesData,
          title:this.createNotesTitle,
          ngo_form:this.ngoId
        }
        this.$store.dispatch('createNewNotesDetailsFun',data).then((res)=>{
          this.getAgainAllCreatedNotesListMethod()
          this.verticalAllNotesListState = true
          this.createNotesData = ''
          this.createNotesTitle = ''
          this.insertedNewNotesBoard = false
        }).catch((err)=>{
          
        })
      }else{
        if(this.getAllNotesListResults.length > 0){
          this.insertedNewNotesBoard = false
          this.verticalAllNotesListState = true
        }else{
          this.notesBoardInsert = false
        }
      }
    },
    updatedInsertedNotesDataMethod(updateData,updateTitle,updateId){
      let data = {
        data:updateData,
        title:updateTitle
      }
      this.$store.dispatch('updateNewNotesDetailsFun',{data:{data:data,id:updateId}}).then((res)=>{
        this.updatedSharedNotesNotificationFalseMethod(updateId)
        // this.sharedNotesListMethod()
        this.getAgainAllNotesDetailsForUpdateMethod()
      }).catch((err)=>{})
    },
    removedInsertedNotesDataMethod(removeId){
      this.notesRemovedId = removeId
      this.notesRemovedPopup = true
      $('body').addClass('body_right_scroll_remove')
    },
    removeModalItemConfirmFun(){
      this.$store.dispatch('deleteNewNotesDetailsFun', {id:this.notesRemovedId}).then((res)=>{
        this.closeRemoveItemModalFun()
        this.getAgainAllCreatedNotesListMethod()
      }).catch((err)=>{})
    },
    closeRemoveItemModalFun(){
      this.notesRemovedId = ''
      this.notesRemovedPopup = false
      this.notesDetailsUpdatedByIDstate = false
      this.verticalAllNotesListState = true
      $('body').removeClass('body_right_scroll_remove')
    },
    updatedSharedNotesNotificationMethod(updateId){
      // console.log("Checking On Key Up Event")
      if(this.keyUpEvent) return;
      let data = {
        under_editing:true,
        edited_by:this.selfUserId
      }
      this.$store.dispatch('updateNewNotesDetailsFun',{data:{data:data,id:updateId}}).then((res)=>{
        this.keyUpEvent = true
      }).catch((err)=>{})
    },
    updatedSharedNotesNotificationFalseMethod(updateId){
      let data = {
        under_editing:false,
        edited_by:this.selfUserId
      }
      this.$store.dispatch('updateNewNotesDetailsFun',{data:{data:data,id:updateId}}).then((res)=>{
        this.keyUpEvent = false
      }).catch((err)=>{})
    },
  },
  watch:{
  }
}