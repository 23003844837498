/*eslint-disable */
import {bus} from '@/main';  
import toast from '@/services/toast';
export default {
  name: 'member-make-administrator',
  components: {},
  props: ['memberAdministratorINFO'],
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
    console.log(this.memberAdministratorINFO)
  },
  methods: {
    closeJoinRecord() {
      bus.$emit('memberAdministratorPopupStateBus', false)
      document.body.classList.remove('popup_open_body')
    },
    confirmJoinRecord(){
      let data = {
        is_administrator:true,
        user:this.memberAdministratorINFO.user.id,
        ngo_form:this.memberAdministratorINFO.ngo_form
      }
      this.$store.dispatch('updateNgoMemberFunc', data).then((res) => {
        toast.success(res.data.msg);
        this.getngoListFun();
        this.closeJoinRecord();
      }).catch((err) => {
        toast.error(err.data.user_msg || err.data.msg);
      })
    },
    getngoListFun() {
      this.$store.dispatch('getNGOInfoNByIdApiFun', {id:this.memberAdministratorINFO.ngo_form})
    },
  }
}