/*eslint-disable */
import {
  mapGetters
} from 'vuex';
import {bus} from '@/main';  
import countries from '@/services/country.json'
import ngoJoinRequestPopup from '@/components/private/approve-request-popup';
import memberAdministratorPopup from '../member-make-administrator/';
export default {
  name: 'settings',
  components: {
    'join-request-popup' : ngoJoinRequestPopup,
    'make-administrator' : memberAdministratorPopup
  },
  props: ['sideBaarExapandSTATE'],
  data () {
    return {
      ngoInfoData:null,
      ngoInfoDataResults:null,
      countryList:[],
      joinRequestPopupState:false,
      joinUserInfo:'',
      memberAdministratorPopupState:false,
      memberAdministratorInfo:'',
      showExtraField:Boolean
    }
  },
  computed: {
    ...mapGetters([
      'getNgoInfoGetters'
    ]),
    getNgoInfoDataFun(){
     this.ngoInfoData = this.$store.getters.getNgoInfoGetters.data
     return;
    },
    setInitialStatesOfNGoFun() {
      if (this.ngoInfoData) {
        this.ngoInfoDataResults = this.ngoInfoData.members
      }
      return;
    },
    showExtraFieldFun(){
      return this.sideBaarExapandSTATE;
    }
  },
  created(){
    this.$store.dispatch('getNGOInfoNByIdApiFun', {id:this.$route.params.Id})
    this.$store.dispatch('getUnapprovedUserOfWorkspaceByIdFunc', {id:this.$route.params.Id})
  },
  mounted () {
    this.showExtraField = this.sideBaarExapandSTATE
    this.getNgoInfoDataFun
    this.setInitialStatesOfNGoFun 
    bus.$on('joinRequestRecordModalBus', (data) => {
      this.joinRequestPopupState = data
    });
    bus.$on('memberAdministratorPopupStateBus', (data) => {
      this.memberAdministratorPopupState = data
    });
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
  },
  methods: {
    memberAdministratorPopupOpenMethod(info){
      this.memberAdministratorInfo = info
      document.body.classList.add('popup_open_body')
      this.memberAdministratorPopupState = true
    },
    joinRequestPopupOpenMethod(info){
      this.joinUserInfo = info
      document.body.classList.add('popup_open_body')
      this.joinRequestPopupState = true
    },
    getCountryNameFun(countryCode) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        country = country.name
      } else {
        country = ''
      }
      return country;
    },
  },
  watch:{
    getNgoInfoDataFun(){
      return this.$store.getters.getNgoInfoGetters;
    },
    setInitialStatesOfNGoFun(){
      if (this.ngoInfoData) {
        this.ngoInfoDataResults =  this.ngoInfoData.members
      }
      return;
    },
    showExtraFieldFun(newVal, oldVal){
      this.showExtraField = newVal
    }
  }
}