/*eslint-disable */
import toast from '@/services/toast';
import {bus} from '@/main'; 
import Vue from "vue";
import { Datetime } from 'vue-datetime';
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)
import {
  mapGetters
} from 'vuex';
export default {
  name: 'create-meeting',
  components: {
    datetime: Datetime,
    Multiselect,
  },
  props: ['nGOId'],
  data () {
    return {
      start_time:'',
      end_time:'',
      topic:'',
      users:'',
      is_public:false,
      options:[],
      submitted:false,
      membersList:null,
      sentUserData:[],
      selfUserId:'',
      selectedAllMembers:false
    }
  },
  computed: {
    ...mapGetters([
      'getNgoInfoGetters'
    ]),
    getNgoMembersListFun() {
      this.membersList = this.$store.getters.getNgoInfoGetters.data;
    },
  },
  mounted () {
    if(localStorage.getItem('LoginedUserID')){
      this.selfUserId = localStorage.getItem('LoginedUserID')
      this.sentUserData.push(this.selfUserId)
    }
    this.getNgoMembersListFun
    this.getMemberListOfNgoMethod();
  },
  methods: {
    getMemberListOfNgoMethod(){
      this.$store.dispatch('getMemberListOfNgoByIdFunc', {id:this.nGOId}).then((res) => {
        let memberList = res.data.data.members;
        for(var i = 0; i < memberList.length; i++){
        if(memberList[i].user.id != this.selfUserId ){
          this.options.push({first_name:memberList[i].user.first_name,last_name:memberList[i].user.last_name,email:memberList[i].user.email, id : memberList[i].user.id})
        }}
      })
    },
    selectedAllMembersMethod(){
      if(this.selectedAllMembers == true){
        this.selectedAllMembers = false
      }else{
        this.selectedAllMembers = true
      }
    },
    getMeetingListAgain(){
      this.$store.dispatch('getMeetingListOfNgoFunc', {id:this.nGOId}).then((res) => {
      })
    },
    createMeetingBeforeSubmitMethod(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted =  true
          if(this.selectedAllMembers == true){
            var data = {
              topic:this.topic,
              start_time:this.start_time,
              end_time:this.end_time,
              all_members:true,
              users:[],
              ngo_form:this.nGOId
            }
          }else{
            for(var i = 0; i < this.users.length; i++){
              this.sentUserData.push(this.users[i].id)
            }
            var data = {
              topic:this.topic,
              start_time:this.start_time,
              end_time:this.end_time,
              users:this.sentUserData,
              is_public:this.is_public,
              all_members:false,
              ngo_form:this.nGOId
            }
          }
          this.$store.dispatch('createMeetingOfNgoFunc', data)
            .then((res) => {  
              toast.success(res.data.msg);
              this.submitted =  false;
              this.getMeetingListAgain();
              this.topic = ''
              this.start_time = ''
              this.end_time = ''
              this.sentUserData = []
              this.users = ''
              this.is_public = false
              this.$validator.reset();
              this.closePopupOfMeetingMethod();
            }).catch((err) => {
              toast.error(err.data.data.email || err.data.user_msg || err.data.msg);
              this.submitted =  false
            })
          return;
        }
      })
    },
    closePopupOfMeetingMethod(){
      bus.$emit('createMeetingPopupStateBus', false)
      document.body.classList.remove('popup_open_body')
    }
  },
}