/*eslint-disable */
import Vue from 'vue'
var $ = window.jQuery = require('jquery');
import toast from '@/services/toast';
import Multiselect from 'vue-multiselect'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
Vue.component('multiselect', Multiselect)
import {
  bus
} from '@/main';
export default {
  name: 'create-group',
  components: {Multiselect,VueCropper},
  props: ['ngoInfoDATA'],
  data () {
    return {
      submitted:false,
      userList:{},
      userListResults:[], 
      options:[],
      topic:'',
      sentUserData:[],
      users:[],
      uploadAttachmentName:'',
      file:null,
      imgSrc:'',
      cropImg:'',
      cropedImg:'',
      is_public:false
    }
  },
  computed: {

  },
  mounted () {
    if(localStorage.getItem('LoginedUserID')){
      this.selfUserId = localStorage.getItem('LoginedUserID')
      this.sentUserData.push(localStorage.getItem('LoginedUserID'))
    }
    this.getMemberListOfNgoMethod();
  },
  methods: {
    getMemberListOfNgoMethod(){
      this.$store.dispatch('getMemberListOfNgoByIdFunc', {id:this.ngoInfoDATA.id}).then((res) => {
        let memberList = res.data.data.members;
        for(var i = 0; i < memberList.length; i++){
        if(memberList[i].user.id != this.selfUserId ){
          this.options.push({first_name:memberList[i].user.first_name,last_name:memberList[i].user.last_name,email:memberList[i].user.email, id : memberList[i].user.id})
        }}
      })
    },
    handleAttachmentUpload(e) {
      const file = e.target.files[0];
      this.uploadAttachmentName = file.name;
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    getGroupRoomListAgain(){
      this.$store.dispatch('getNgoMemberChatRoomListFunc', {id:this.ngoInfoDATA.id}).then((res) => {
        this.$store.dispatch('getGroupListOfNGOFunc', {id:this.ngoInfoDATA.id})
      })
    },
    cropImage(){
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      let cropperImg = this.cropImg;
      this.cropedImg = cropperImg.replace(/^data:image\/(png|jpg);base64,/, "")
      this.cancelCropCloseMethod()
    },
    cancelCropMethod(){
      setTimeout(()=>{
        this.imgSrc = '';
        this.cropedImg = ''
        this.uploadAttachmentName = ''
        this.$refs.cropper.reset();
      },10)
    },
    cancelCropCloseMethod(){
      setTimeout(()=>{
        this.imgSrc = '';
        this.$refs.cropper.reset();
      },10)
    },
    createGroupChatBeforeSubmitMethod(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted =  true
          for(var i = 0; i < this.users.length; i++){
            this.sentUserData.push(this.users[i].id)
          }
          var data = {
            name:this.topic,
            is_support_chat:false,
            is_group_chat:true,
            is_ngo_chat:true,
            ngo_id:this.ngoInfoDATA.id,
            users:this.sentUserData,
            is_public:this.is_public
          }
          this.$store.dispatch('createGroupOfNGOMemberFunc', data)
            .then((res) => { 
              toast.success(res.data.msg);
              this.getGroupRoomListAgain()
              this.createGroupPopupDisabledFun()
              this.submitted =  false
              if(this.cropedImg != ''){
                var formData = new FormData();
                formData.append('group_image', this.file);
                let dataFile = {
                  "group_image": this.cropedImg,
                  "filename": this.uploadAttachmentName,
                }
                this.$store.dispatch('groupDpPhotoUploadFunc', {data:{data:dataFile,id:res.data.data.data.id}}).then((res) => {
                  this.cropedImg = ''
                  toast.success(res.data.msg);
                  this.getGroupRoomListAgain()
                  this.createGroupPopupDisabledFun()
                  this.submitted =  false
                }).catch((err)=>{
                  this.submitted =  false
                  toast.error(err.data.msg);
                })
              }else{
                toast.success(res.data.msg);
                this.getGroupRoomListAgain()
                this.createGroupPopupDisabledFun()
                this.submitted =  false
              } 
            }).catch((err) => {
              toast.error(err.data.msg);
              this.submitted =  false
            })
          return;
        }
      })
    },
    createGroupPopupDisabledFun(){
      document.body.classList.remove('popup_open_body')
      bus.$emit('createGroupPopupBus', false);
    }
  }
}