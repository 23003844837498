/*eslint-disable */
var $ = window.jQuery = require('jquery');
import {
  bus
} from '@/main';
import { VueEditor } from "vue2-editor";
export default {
  name: 'notes-view-section',
  components: {
    VueEditor
  },
  props: ['notesViewDATA'],
  data () {
    return {
      fullViewModeEditable:true,
      notesViewData:{},
      keyUpEvent:false,
      selfUserId:'',
      editorOptions: {
        placeholder: 'Description...',
        editorToolbar: ['bold','italic','link']
      },
      editorData: '',
    }
  },
  computed: {

  },
  mounted () {
    this.notesViewData = this.notesViewDATA
    if (localStorage.getItem('LoginedUserID')) {
      this.selfUserId = localStorage.getItem('LoginedUserID')
    }
  },
  methods: {
    backToNormalViewMethod(){
      $('body').removeClass('body_right_scroll_remove')
      bus.$emit('notesViewPopupBus', false);
    },
    fullViewModeEditableMethod(){
      if(this.fullViewModeEditable == true){
        this.fullViewModeEditable = false
      }else{
        this.fullViewModeEditable = true
      }
    },
    updatedInsertedNotesDataMethod(updateData,updateTitle,updateId){
      let data = {
        data:updateData,
        title:updateTitle
      }
      this.$store.dispatch('updateNewNotesDetailsFun',{data:{data:data,id:updateId}}).then((res)=>{
        this.updatedSharedNotesNotificationFalseMethod(updateId)
      }).catch((err)=>{})
    },
    updatedSharedNotesNotificationMethod(updateId){
      if(this.keyUpEvent) return;
      let data = {
        under_editing:true,
        edited_by:this.selfUserId
      }
      this.$store.dispatch('updateNewNotesDetailsFun',{data:{data:data,id:updateId}}).then((res)=>{
        this.keyUpEvent = true
      }).catch((err)=>{})
    },
    updatedSharedNotesNotificationFalseMethod(updateId){
      let data = {
        under_editing:false,
        edited_by:this.selfUserId
      }
      this.$store.dispatch('updateNewNotesDetailsFun',{data:{data:data,id:updateId}}).then((res)=>{
        this.keyUpEvent = false
      }).catch((err)=>{})
    },
    getAgainAllNotesDetailsForUpdateMethod(){
      this.$store.dispatch('getAllNotesListFun').then((res)=>{
      }).catch((err)=>{})
    },
    sharedNotesListMethod(){
      this.$store.dispatch('getAllSharedNotesListFun').then((res)=>{
      }).catch((err)=>{})
    },
  }
}


