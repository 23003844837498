/*eslint-disable */
import Vue from 'vue'
var $ = window.jQuery = require('jquery');
import toast from '@/services/toast';
import Multiselect from 'vue-multiselect'
import {
  bus
} from '@/main';
export default {
  name: 'shared-notes',
  components: {Multiselect},
  props: ['sharedNotesINFO'],
  data () {
    return {
      submitted:false,
      userList:null,
      userListResults:[], 
      options:[],
      name:'',
      sentUserData:[],
      users:[],
      sharedNotesId:'',
      selection_type:'User',
      groupUserList:null,
      groupUserListResults:[],
      groupOptions:[],
      write_access:false
    }
  },
  computed: {

  },
  mounted () {
    this.getAllUserListMethod()
    this.name = this.sharedNotesINFO.title
    this.sharedNotesId = this.sharedNotesINFO.id
  },
  methods: {
    selectedOptionMethod(item){
      this.users = []
      this.sentUserData = []
      this.$validator.reset()
      this.selection_type = item
      if(item === 'User') return
      if(this.groupUserList) return;
      this.$store.dispatch('getGroupChatRoomCreatedFun').then((res) => {
        this.groupUserList = res.data.data
        if(this.groupUserList != undefined){
          this.groupUserListResults = this.groupUserList
          for(var i = 0; i < this.groupUserListResults.length; i++){
            this.groupOptions.push({gName:this.groupUserListResults[i].name, id : this.groupUserListResults[i].id})
          }
        }
      })
    },
    getAllUserListMethod() {
     this.$store.dispatch('isConditionalListOfAllUsersFun').then((res) => {
       this.userList = res.data.data
       if(this.userList != undefined){
         this.userListResults = this.userList
         for(var i = 0; i < this.userListResults.length; i++){
           this.options.push({first_name:this.userListResults[i].first_name,last_name:this.userListResults[i].last_name,email:this.userListResults[i].email, id : this.userListResults[i].id})
         }
       }
     }).catch((err)=>{})
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.users.push(tag) 
    },
    customGroupLabel ({ gName }) {
      return `${gName}`
    },
    customLabel ({ email, first_name, last_name }) {
      return `${first_name} ${last_name} - ${email}`
    },
    sharedNotesMethod(){
      this.sentUserData = []
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted =  true
          if(this.selection_type === 'User'){
            for(var i = 0; i < this.users.length; i++){
              this.sentUserData.push(this.users[i].id)
            }
            var data = {
              user_notes:this.sharedNotesId,
              user:this.sentUserData,
              write_access:this.write_access
            }
            this.$store.dispatch('newNotesSharedFun', data)
              .then((res) => { 
                toast.success(res.data.msg);
                this.submitted =  false
                this.sharedNotesPopupDisabledFun()
              }).catch((err) => {
                toast.error(err.data.msg);
                this.submitted =  false
              })
              return;
          }else{
            var data = {
              user_notes:this.sharedNotesId,
              chat_room:this.users.id
            }
            this.$store.dispatch('createNotesSharedInGroupFun', data)
              .then((res) => { 
                toast.success(res.data.msg);
                this.submitted =  false
                this.sharedNotesPopupDisabledFun()
              }).catch((err) => {
                toast.error(err.data.msg);
                this.submitted =  false
              })
              return;
          }
        }
      })
    },
    sharedNotesPopupDisabledFun(){
      $('body').removeClass('body_right_scroll_remove')
      bus.$emit('sharedNotesBus', false);
      this.sentUserData = []
      this.sharedNotesId = ''
      this.name = ''
    }
  }
}